import { Button } from "antd";
import { MdOutlineArrowBackIos } from "react-icons/md";
import EmployeeIcon from "../../../reuseables/EmployeeIcon";
import { Link } from "react-router-dom";
import { LiaHourglassEndSolid } from "react-icons/lia";
import ApprovedModal from "./EmployeeDetailModals/ApprovedModal";
import DeclineModal from "./EmployeeDetailModals/DeclineModal";

type EmpAppDtlsProps = {
  id: number;
  openProfile: (open: boolean) => void;
};

export default function EmpAppDtls({ id, openProfile }: EmpAppDtlsProps) {
  //   const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const getStatus = (stat: number) => {
    const status = [
      "",
      "Ongoing",
      "Approved",
      "Completed",
      "Cancelled",
      "Pending",
      "Declined",
    ];
    return status[stat];
  };
  const getLeaveType = (type: number) => {
    const types = ["", "Sick Leave", "Vocation Leave", "Maternity Leave"];
    return types[type];
  };

  const statusStyle = (stat: number) => {
    const status = [
      "",
      "text-[#98690CFF] bg-[#fef9eeff]",
      "text-[#379AE6FF] bg-[#c1e4ff]",
      "text-[#117b34ff] bg-[#ccffdd]",
      "text-violet-500 bg-violet-50",
      "text-[#98690CFF] bg-[#fef9eeff]",
      "bg-gray-100",
    ];

    return status[stat];
  };

  const sampleData = [
    {
      id: 1,
      empId: 1,
      lastName: "Johnson",
      firstName: "Emily",
      leaveType: 2,
      paid: true,
      status: 1,
      profile: "https://randomuser.me/api/portraits/women/1.jpg",
    },
    {
      id: 2,
      empId: 2,
      lastName: "Brown",
      firstName: "Michael",
      leaveType: 1,
      paid: false,
      status: 2,
      profile: "https://randomuser.me/api/portraits/men/2.jpg",
    },
    {
      id: 3,
      empId: 3,
      lastName: "Williams",
      firstName: "Sarah",
      leaveType: 3,
      paid: true,
      status: 3,
      profile: "https://randomuser.me/api/portraits/women/3.jpg",
    },
    {
      id: 4,
      empId: 4,
      lastName: "Jones",
      firstName: "David",
      leaveType: 2,
      paid: false,
      status: 1,
      profile: "https://randomuser.me/api/portraits/men/4.jpg",
    },
    {
      id: 5,
      empId: 5,
      lastName: "Garcia",
      firstName: "Anna",
      leaveType: 1,
      paid: true,
      status: 4,
      profile: "https://randomuser.me/api/portraits/women/5.jpg",
    },
    {
      id: 6,
      empId: 6,
      lastName: "Martinez",
      firstName: "Chris",
      leaveType: 3,
      paid: true,
      status: 6,
      profile: "https://randomuser.me/api/portraits/men/6.jpg",
    },
    {
      id: 7,
      empId: 7,
      lastName: "Davis",
      firstName: "Jessica",
      leaveType: 2,
      paid: false,
      status: 5,
      profile: "https://randomuser.me/api/portraits/women/7.jpg",
    },
    {
      id: 8,
      empId: 8,
      lastName: "Miller",
      firstName: "Tom",
      leaveType: 1,
      paid: true,
      status: 1,
      profile: "https://randomuser.me/api/portraits/men/8.jpg",
    },
    {
      id: 9,
      empId: 9,
      lastName: "Hernandez",
      firstName: "Sofia",
      leaveType: 3,
      paid: false,
      status: 3,
      profile: "https://randomuser.me/api/portraits/women/9.jpg",
    },
    {
      id: 10,
      empId: 10,
      lastName: "Rodriguez",
      firstName: "Juan",
      leaveType: 2,
      paid: true,
      status: 2,
      profile: "https://randomuser.me/api/portraits/men/10.jpg",
    },
  ];

  const isApproved = sampleData[id]?.status === 2;
  const isOngoing = sampleData[id]?.status === 1;
  const isPending = sampleData[id]?.status === 5;
  const isDeclined = sampleData[id]?.status === 6;
  const handleBackEmpDtl = () => openProfile(false);
  return (
    <div className="bg-white p-4">
      {/* Emp Details Header */}
      <div className="flex items-center gap-4">
        <Button
          className="font-bold bg-gray-50 rounded-full"
          shape="circle"
          style={{
            backgroundColor: "#eaecefff",
            borderColor: "#4CAF50",
            color: "#565e6cff",
          }}
          onClick={handleBackEmpDtl}
        >
          <MdOutlineArrowBackIos />
        </Button>
        <div className="flex gap-4 items-center">
          <EmployeeIcon profile={sampleData[id]?.profile} />
          <div className="">
            <p className="font-semibold text-lg">
              <span>
                {sampleData[id]?.firstName} {sampleData[id]?.lastName}
              </span>
            </p>
            <p className="text-gray-500">
              <span>Employee ID: {sampleData[id]?.empId}</span>
            </p>
          </div>
        </div>
        <div
          className={`${statusStyle(
            sampleData[id]?.status
          )} ml-auto self-start p-2 rounded-full text-sm w-24 flex justify-center`}
        >
          <span>{getStatus(sampleData[id]?.status)}</span>
        </div>
      </div>
      {/* Emp Details Header END*/}
      <div className="ml-4 mt-8 flex flex-col gap-3 text-[#565e6cff]">
        <div className="grid grid-cols-3">
          <label htmlFor="" className="font-semibold">
            Leave Type
          </label>
          <p>
            <span>{getLeaveType(sampleData[id]?.leaveType)}</span>
          </p>
        </div>
        <div>
          <label htmlFor="" className="font-semibold">
            <input type="checkbox" checked={true} />
            Paid
          </label>
        </div>
        <div className="grid grid-cols-3">
          <label htmlFor="" className="font-semibold">
            Date Requested
          </label>
          <p>
            <span>July 1, 2024</span>
          </p>
        </div>
        <div className="grid grid-cols-3">
          <label htmlFor="" className="font-semibold">
            Date Approved
          </label>
          <p>
            <span>July 1, 2024</span>
          </p>
        </div>
        <p className="font-semibold">
          <span>Date of Leave</span>
        </p>
        <div className="grid grid-cols-2">
          <div className="flex">
            <label htmlFor="" className="font-semibold">
              From
            </label>
            <p className="ml-8">
              <span>July 1, 2024</span>
            </p>
          </div>
          <div className="flex">
            <label htmlFor="" className="font-semibold">
              To
            </label>
            <p className="ml-8">
              <span>July 1, 2024</span>
            </p>
          </div>
        </div>
        <div className="flex">
          <label htmlFor="" className="font-semibold">
            Days
          </label>
          <p className="ml-8">
            <span>10</span>
          </p>
        </div>
        <div className="grid grid-cols-3">
          <label htmlFor="" className="font-semibold">
            Attachments
          </label>
          <input type="text" />
        </div>
        <div className="flex">
          <label htmlFor="" className="font-semibold">
            Requestor Remarks
          </label>
          <p className="ml-4">
            <span>N/A</span>
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="" className="font-semibold">
            Requestor Signature
          </label>
          <div className="h-14 w-72 bg-gray-100"></div>
        </div>
        {!isPending && (
          <>
            <div className="flex gap-2">
              <label htmlFor="" className="font-semibold flex-shrink-0 w-40">
                Approver Remarks
              </label>
              <p className="">
                <span>Unpaid - leave balance is already low</span>
              </p>
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="" className="font-semibold">
                Approver Signature
              </label>
              <div className="h-14 w-72 bg-gray-100"></div>
            </div>
          </>
        )}
        {isOngoing && (
          <div className="">
            <p className="flex items-center gap-3">
              <span className="text-3xl text-blue-500">
                <LiaHourglassEndSolid />
              </span>
              <span>10 days left</span>
            </p>
          </div>
        )}
      </div>
      <div className="mt-10 mb-1 flex justify-center flex-col gap-4 items-center">
        {isApproved && (
          <div className="flex gap-4">
            <Button type="primary">Cancel Leave</Button>
            <Button>Edit</Button>
          </div>
        )}
        {isDeclined && (
          <div className="flex gap-4">
            <Button>Edit</Button>
          </div>
        )}
        {isPending && (
          <div className="flex gap-4">
            <ApprovedModal />
            <DeclineModal />
          </div>
        )}
        <Link to={"#"} className="underline underline-offset-4 text-blue-600">
          View Leave History
        </Link>
      </div>
    </div>
  );
}
