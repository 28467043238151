import React, { useState } from "react";
import { Table, Input, Pagination } from "antd";
import type { PaginationProps, TableColumnsType } from "antd";

import { BsSearch } from "react-icons/bs";
import { useFetchDocumentsPageQuery } from "../../../../store";
import { Employee } from "./Type";
import { sampleHRdocuments } from "./SampleData";
import EmployeeIcon from "../../../reuseables/EmployeeIcon";
import AddNewDocument from "../../../modals/AddNewDocument";

type eventTypes = React.KeyboardEventHandler<HTMLInputElement> | any;

export default function TwoOOne() {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState<string>("");
  const [pageSize] = useState(2);

  const documentFromApi = useFetchDocumentsPageQuery({
    page,
    pageSize,
    search,
  });

  // search function
  const handleSearchChange = (event: eventTypes) => {
    setSearch(event.target.value);
    setPage(1);
  };

  // function for change the page
  const onChangePage: PaginationProps["onChange"] = (page) => {
    setPage(page);
  };

  const dataTable = sampleHRdocuments;

  const columns: TableColumnsType<Employee> = [
    {
      key: "profile",
      fixed: "left",
      dataIndex: "profile",
      title: () => <p className="font-sans text-zinc-700"></p>,
      render: (profile) => <EmployeeIcon profile={profile} />,
    },
    {
      key: "id",
      dataIndex: "id",
      fixed: "left",
      title: () => <p className="font-sans text-zinc-700">Employee ID</p>,
    },
    {
      key: "lastName",
      dataIndex: "lastName",
      fixed: "left",
      title: () => <p className="font-sans text-zinc-700">Last Name</p>,
    },
    {
      key: "firstName",
      dataIndex: "firstName",
      fixed: "left",
      title: () => <p className="font-sans text-zinc-700">First Name</p>,
    },
    {
      key: "midName",
      dataIndex: "midName",
      fixed: "left",
      title: () => <p className="font-sans text-zinc-700">Middle Name</p>,
    },
    {
      key: "supervisor",
      dataIndex: "supervisor",
      title: () => <p className="font-sans text-zinc-700">Supervisor</p>,
    },
    {
      dataIndex: "workArrangement",
      key: "workArrangement",
      title: () => <p className="font-sans text-zinc-700">Work Arrangement</p>,
    },
    {
      dataIndex: "empType",
      key: "empType",
      title: () => <p className="font-sans text-zinc-700">Employment Type</p>,
    },
    {
      dataIndex: "empStatus",
      key: "empStatus",
      title: () => <p className="font-sans text-zinc-700">Employment Status</p>,
    },
    {
      dataIndex: "contractStartDate",
      key: "contractStartDate",
      title: () => (
        <p className="font-sans text-zinc-700">Contract Start Date</p>
      ),
    },
    {
      dataIndex: "contractEndDate",
      key: "contractEndDate",
      title: () => <p className="font-sans text-zinc-700">Contract End Date</p>,
    },
    {
      dataIndex: "regularization",
      key: "regularization",
      title: () => <p className="font-sans text-zinc-700">Regularization</p>,
    },
    {
      dataIndex: "daysUntilReg",
      key: "daysUntilReg",
      title: () => (
        <p className="font-sans text-zinc-700">Days until regularization</p>
      ),
    },
    {
      dataIndex: "daysUntilEndContract",
      key: "daysUntilEndContract",
      title: () => (
        <p className="font-sans text-zinc-700">Days until end of contract</p>
      ),
    },
    {
      dataIndex: "status",
      key: "status",
      title: () => <p className="font-sans text-zinc-700">Status</p>,
    },
    {
      dataIndex: "personalDatasheet",
      key: "personalDatasheet",
      title: () => (
        <p className="font-sans text-zinc-700">Personal Data Sheet</p>
      ),
    },
    {
      dataIndex: "level",
      key: "level",
      title: () => <p className="font-sans text-zinc-700">Level</p>,
    },
    {
      dataIndex: "position",
      key: "position",
      title: () => <p className="font-sans text-zinc-700">Position</p>,
    },
    {
      dataIndex: "department",
      key: "department",
      title: () => <p className="font-sans text-zinc-700">Department</p>,
    },
  ];

  return (
    <div>
      {" "}
      <div className=" mt-10 space-y-5">
        <div className=" flex items-center justify-between">
          <Input
            placeholder="Search.."
            defaultValue={search}
            onKeyUp={handleSearchChange}
            className="custom-style-input w-72"
            suffix={<BsSearch />}
          />
          <AddNewDocument />
        </div>
        <Table
          style={{ borderBlockColor: "#d4d4d8" }}
          bordered
          rowKey="id"
          pagination={false}
          columns={columns}
          scroll={{ x: "max-content" }}
          dataSource={dataTable}
          rowClassName=" font-sans text-neutral-600"
        />
        <Pagination
          className=" flex justify-end"
          current={page}
          onChange={onChangePage}
          total={documentFromApi.data?.count}
          defaultPageSize={pageSize}
        />
      </div>
    </div>
  );
}
