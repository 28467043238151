import { Employee } from "./Type";

export const sampleHRdocuments: Employee[] = [
  {
    id: 1,
    lastName: "Rowsal",
    firstName: "Rosa",
    midName: "Crop",
    manager: "Jazteen Faye Cullarin",
    supervisor: "Jazteen Faye Cullarin",
    workArrangement: "Hybrid",
    empType: "Full time",
    empStatus: "Probationary",
    contractStartDate: "July 1, 2024",
    contractEndDate: "July 1, 2025",
    regularization: "October 1, 2024",
    daysUntilReg: 60,
    daysUntilEndContract: 365,
    status: 1,
    personalDatasheet: 1,
    level: 1,
    position: "HR Manager",
    department: "HR Department",
    profile: "https://randomuser.me/api/portraits/women/1.jpg",
    nbipoliceClearance: 2,
    medCert: 1,
    coe: 3,
    brgyClearance: 1,
    dpndBirthCert: 2,
  },
  {
    id: 2,
    lastName: "Smith",
    firstName: "John",
    midName: "Allen",
    manager: "Amanda Torres",
    supervisor: "Amanda Torres",
    workArrangement: "Onsite",
    empType: "Full time",
    empStatus: "Active",
    contractStartDate: "March 15, 2023",
    contractEndDate: "March 15, 2024",
    regularization: "June 15, 2023",
    daysUntilReg: 30,
    daysUntilEndContract: 120,
    status: 2,
    personalDatasheet: 2,
    level: 3,
    position: "Software Engineer",
    department: "IT Department",
    profile: "https://randomuser.me/api/portraits/men/2.jpg",
    nbipoliceClearance: 1,
    medCert: 2,
    coe: 3,
    brgyClearance: 1,
    dpndBirthCert: 3,
  },
  {
    id: 3,
    lastName: "Johnson",
    firstName: "Sarah",
    midName: "Jane",
    manager: "Eliza Bennett",
    supervisor: "Eliza Bennett",
    workArrangement: "Remote",
    empType: "Part time",
    empStatus: "Probationary",
    contractStartDate: "May 1, 2024",
    contractEndDate: "May 1, 2025",
    regularization: "August 1, 2024",
    daysUntilReg: 45,
    daysUntilEndContract: 330,
    status: 1,
    personalDatasheet: 1,
    level: 2,
    position: "Data Analyst",
    department: "Data Department",
    profile: "https://randomuser.me/api/portraits/women/3.jpg",
    nbipoliceClearance: 3,
    medCert: 1,
    coe: 2,
    brgyClearance: 2,
    dpndBirthCert: 3,
  },
  {
    id: 4,
    lastName: "Williams",
    firstName: "James",
    midName: "Arthur",
    manager: "Elena Martin",
    supervisor: "Elena Martin",
    workArrangement: "Hybrid",
    empType: "Contract",
    empStatus: "Active",
    contractStartDate: "September 10, 2023",
    contractEndDate: "September 10, 2024",
    regularization: "December 10, 2023",
    daysUntilReg: 90,
    daysUntilEndContract: 150,
    status: 3,
    personalDatasheet: 2,
    level: 4,
    position: "Consultant",
    department: "Consulting Department",
    profile: "https://randomuser.me/api/portraits/men/4.jpg",
    nbipoliceClearance: 2,
    medCert: 3,
    coe: 1,
    brgyClearance: 3,
    dpndBirthCert: 1,
  },
  {
    id: 5,
    lastName: "Brown",
    firstName: "Lisa",
    midName: "Marie",
    manager: "Daniel Walker",
    supervisor: "Daniel Walker",
    workArrangement: "Remote",
    empType: "Full time",
    empStatus: "Inactive",
    contractStartDate: "August 20, 2023",
    contractEndDate: "August 20, 2024",
    regularization: "November 20, 2023",
    daysUntilReg: 60,
    daysUntilEndContract: 100,
    status: 1,
    personalDatasheet: 3,
    level: 1,
    position: "Graphic Designer",
    department: "Marketing",
    profile: "https://randomuser.me/api/portraits/women/5.jpg",
    nbipoliceClearance: 1,
    medCert: 3,
    coe: 2,
    brgyClearance: 1,
    dpndBirthCert: 3,
  },
  {
    id: 6,
    lastName: "Jones",
    firstName: "Michael",
    midName: "Robert",
    manager: "Sophia Clark",
    supervisor: "Sophia Clark",
    workArrangement: "Onsite",
    empType: "Part time",
    empStatus: "Active",
    contractStartDate: "November 1, 2023",
    contractEndDate: "November 1, 2024",
    regularization: "February 1, 2024",
    daysUntilReg: 90,
    daysUntilEndContract: 180,
    status: 2,
    personalDatasheet: 2,
    level: 3,
    position: "Financial Analyst",
    department: "Finance",
    profile: "https://randomuser.me/api/portraits/men/6.jpg",
    nbipoliceClearance: 3,
    medCert: 2,
    coe: 1,
    brgyClearance: 2,
    dpndBirthCert: 3,
  },
  {
    id: 7,
    lastName: "Garcia",
    firstName: "Emily",
    midName: "Grace",
    manager: "James Hill",
    supervisor: "James Hill",
    workArrangement: "Hybrid",
    empType: "Full time",
    empStatus: "Probationary",
    contractStartDate: "December 1, 2023",
    contractEndDate: "December 1, 2024",
    regularization: "March 1, 2024",
    daysUntilReg: 120,
    daysUntilEndContract: 210,
    status: 1,
    personalDatasheet: 3,
    level: 2,
    position: "Project Manager",
    department: "Project Management",
    profile: "https://randomuser.me/api/portraits/women/7.jpg",
    nbipoliceClearance: 2,
    medCert: 1,
    coe: 3,
    brgyClearance: 3,
    dpndBirthCert: 2,
  },
  {
    id: 8,
    lastName: "Martinez",
    firstName: "David",
    midName: "Edward",
    manager: "Emma Scott",
    supervisor: "Emma Scott",
    workArrangement: "Remote",
    empType: "Full time",
    empStatus: "Active",
    contractStartDate: "February 5, 2023",
    contractEndDate: "February 5, 2024",
    regularization: "May 5, 2023",
    daysUntilReg: 0,
    daysUntilEndContract: 5,
    status: 1,
    personalDatasheet: 2,
    level: 3,
    position: "Research Scientist",
    department: "R&D",
    profile: "https://randomuser.me/api/portraits/men/8.jpg",
    nbipoliceClearance: 3,
    medCert: 2,
    coe: 1,
    brgyClearance: 1,
    dpndBirthCert: 3,
  },
  {
    id: 9,
    lastName: "Rodriguez",
    firstName: "Sophia",
    midName: "Hope",
    manager: "Liam Green",
    supervisor: "Liam Green",
    workArrangement: "Onsite",
    empType: "Contract",
    empStatus: "Probationary",
    contractStartDate: "January 10, 2024",
    contractEndDate: "January 10, 2025",
    regularization: "April 10, 2024",
    daysUntilReg: 50,
    daysUntilEndContract: 340,
    status: 2,
    personalDatasheet: 3,
    level: 4,
    position: "Customer Success Manager",
    department: "Customer Service",
    profile: "https://randomuser.me/api/portraits/women/9.jpg",
    nbipoliceClearance: 1,
    medCert: 3,
    coe: 2,
    brgyClearance: 3,
    dpndBirthCert: 1,
  },
];
