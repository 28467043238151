import CustomContainer from '../../reuseables/CustomContainer';
import EventList from './EventList';


const EventTabs = () => {

  return (
    <CustomContainer>
      <EventList/>
    </CustomContainer>
  );
};

export default EventTabs;