import React, { useState} from 'react'
import { Button, Avatar, Typography } from "@mui/material";
import { CalendarToday, AccessTime, Place } from "@mui/icons-material";
import { CiCalendar } from "react-icons/ci";
import { FaRegRegistered } from "react-icons/fa";
import {
  Input
} from "antd";
import { BsSearch } from "react-icons/bs";
import { FaChevronLeft, FaChevronRight, FaUser } from "react-icons/fa";
import dayjs from "dayjs";
import AddEvent from './AddEvent';
import EventView from '../../modals/WhatsNew/EventView';


interface Event {
    title: string;
    date: string;
    time: string;
    location: string;
    seats: string;
    image: string;
}

export interface TabType {
    label: string;
    value: number;
    component?: JSX.Element;
}

const EventCard = ({ event }: { event: Event }) => (
  <div className="bg-white rounded-lg shadow-sm">
    <div className="p-4">
      <img
        src={event.image}
        alt={event.title}
        className="w-full h-32 object-cover rounded-lg mb-2"
      />
      <h3 className="text-lg font-semibold">{event.title}</h3>
      <div className="text-sm text-gray-500 mt-1 flex items-center gap-2">
          <CalendarToday fontSize="small" /> {event.date} | {event.time}
      </div>
      <div className="text-sm text-gray-500 flex items-center gap-2">
          <Place fontSize="small" /> {event.location}
      </div>
    </div>
    <div className="border-t-2 border-gray-300 p-4">
      <div className="mt-2 flex justify-between items-center">
        {/* <Chip label={event.seats} color="success" size="small" /> */}
        <div className="flex gap-1">
          <Button variant="contained" sx={{
            backgroundColor: '#F5FCEB',
            color: '#68B046FF',
            '&:hover': {
            backgroundColor: '#EFFAE0FF',
            color: '#68B046FF'
            },
          }} size="small">
            I'm interested
          </Button>
          <Button  sx={{
            backgroundColor: '#FFFFFFFF',
            color: '#555',
            borderBlockColor: '#555',
          }} size="small">
            Can't make it
          </Button>
        </div>
      </div>
      <div className="mt-2 flex gap-2">
        <Button
          startIcon={<CiCalendar />}
          variant="text"
          sx={{
              backgroundColor: '#FFFFFFFF',
              color: '#777',
              borderBlockColor: '#777',
          }}
        >
          Add to Calendar
        </Button>
        <Button
          startIcon={<FaRegRegistered/>}
          variant="text"
          sx={{
            backgroundColor: '#FFFFFFFF',
            color: '#777',
            borderBlockColor: '#777',
          }}
        >
          Register
        </Button>
      </div>
    </div>
  </div>
);

const EventCardArchive = ({ event }: { event: Event }) => (
    <div className="bg-white rounded-lg shadow-sm">
      <div className="p-4">
        <img
          src={event.image}
          alt={event.title}
          className="w-full h-32 object-cover rounded-lg mb-2"
        />
        <h3 className="text-lg font-semibold">{event.title}</h3>
        <div className="text-sm text-gray-500 mt-1 flex items-center gap-2">
          <CalendarToday fontSize="small" /> {event.date} | {event.time}
        </div>
        <div className="text-sm text-gray-500 flex items-center gap-2">
          <Place fontSize="small" /> {event.location}
        </div>
      </div>
    </div>
  );

const EventList = () => {
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const handleSearchChange = (event: any) => {
        setSearch(event.target.value);
        setPage(1);
    };

    // Date Event
    const [currentDate, setCurrentDate] = useState(dayjs());

    const daysInMonth = currentDate.daysInMonth();
    const firstDayOfMonth = currentDate.startOf("month").day();
  
    const handlePreviousMonth = () => setCurrentDate(currentDate.subtract(1, "month"));
    const handleNextMonth = () => setCurrentDate(currentDate.add(1, "month"));

    const startOfWeek = currentDate.startOf("week");
    const weekDays = Array.from({ length: 7 }, (_, i) =>
      startOfWeek.add(i, "day").format("D")
    );
    const today = dayjs().format("D");

    // Tab function
    const [activeTab, setActiveTab] = useState<String>('');

    const handleChange = (tab: string) => {
        setActiveTab(tab);
    };
  
    const events = [
      {
        title: "Marathon",
        date: "June 06, 2024",
        time: "06:00 AM - 12:00 PM",
        location: "Pasig City",
        seats: "65/100",
        image: "../images/whatsNew/event1.jpg",
      },
      {
        title: "Rock Festival",
        date: "June 06, 2024",
        time: "06:00 AM - 12:00 PM",
        location: "Pasig City",
        seats: "65/100",
        image: "../images/whatsNew/event2.jpg",
      },
      {
        title: "Harmony of Melodies Concert",
        date: "June 06, 2024",
        time: "06:00 AM - 12:00 PM",
        location: "Pasig City",
        seats: "65/100",
        image: "../images/whatsNew/event3.jpg",
      },
    ];

    const CardList = () => {
        return (
            <>
                <div className="mt-10 flex items-center justify-between">
                    <div className="flex gap-2">
                        <Input
                            placeholder="Search.."
                            defaultValue={search}
                            onKeyUp={handleSearchChange}
                            className="custom-style-input w-72"
                            suffix={<BsSearch />}
                        />
                    </div>
                    <div className='g-4' style={{ display: 'flex', gap: '16px' }}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#68B046FF',
                                color: '#FFFFFFFF',
                                '&:hover': {
                                backgroundColor: '#68B046FF',
                                color: '#FFFFFFFF'
                                },
                            }}
                            onClick={() => handleChange('addEvent')}
                        >
                            <Typography className=" font-sans text-slate-100">
                                Add New
                            </Typography>
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#0056D2FF',
                                color: '#FFFFFFFF',
                                '&:hover': {
                                backgroundColor: '#003C91FF',
                                color: '#FFFFFFFF'
                                }
                            }}
                            onClick={() => handleChange('drafts')}
                        >
                            <Typography className=" font-sans text-slate-100">
                            Drafts
                            </Typography>
                        </Button>
                        <EventView/>
                    </div>
                </div>
                {/* Event List card */}
                <div className="mt-10 space-y-5 p-8 rounded-md shadow-md">
                    {/* Next Event */}
                    <div className="mb-8">
                        <div className="flex mb-4">
                            <img src="../images/whatsNew/whatNewImagetableHeader.png" alt="What's New" className="mr-2 h-18 w-20" />
                            <h2 className="text-2xl font-bold text-orange-500">Events</h2>
                        </div>
                        <p className="text-gray-600 mb-8">
                            Stay updated with the latest features, improvements, and announcements! Explore our ‘What’s New’ page to discover fresh content, product updates, and exciting developments happening in our community.
                        </p>
                        <h2 className="text-2xl font-bold mb-4">Next Event</h2>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {/* Event Details Section */}
                            <div className="bg-white rounded-lg shadow-lg p-4 flex">
                                <img
                                    src="../images/whatsNew/eventD.jpg"
                                    alt="Next Event"
                                    className="w-1/3 h-40 object-cover rounded-lg mr-4"
                                />
                                <div className="flex flex-col justify-between">
                                    <h3 className="text-xl font-semibold">Event Title</h3>

                                    <div className="text-sm text-gray-600 mt-2 flex items-center gap-2">
                                        <CalendarToday fontSize="small" /> January 13, 2024
                                    </div>
                                    <div className="text-sm text-gray-600 flex items-center gap-2">
                                        <AccessTime fontSize="small" /> 12:00 PM - 5:00 PM
                                    </div>
                                    <div className="text-sm text-gray-600 flex items-center gap-2">
                                        <Place fontSize="small" /> Pasig City
                                    </div>
                                    <div className="text-sm text-gray-600 flex items-center gap-2">
                                        <span>Seat: 100</span>
                                    </div>

                                    {/* Participants */}
                                    <div className="mt-2 flex items-center gap-2">
                                        <Avatar>A</Avatar>
                                        <Avatar>A</Avatar>
                                        <Avatar>A</Avatar>
                                        <Avatar>A</Avatar>
                                        <Avatar>A</Avatar>
                                        <span className="text-gray-600">+ 61 others</span>
                                    </div>
                                    <p className="text-gray-600 mb-8">
                                        Voluptate velit nulla cillum duis commodo excepteur esse laboris dolor esse.
                                        Tempor dolore aliqua eu elit ad laboris incididunt et.
                                    </p>
                                    {/* Interest and Registration Buttons */}
                                    <div className="flex gap-2 mt-4">
                                        <Button
                                            variant="contained"
                                            sx={{
                                                backgroundColor: '#68B046FF',
                                                color: '#FFF',
                                                '&:hover': { backgroundColor: '#5CA940FF' }
                                            }}
                                        >
                                            I'm interested
                                        </Button>
                                        <Button variant="outlined" sx={{ borderColor: '#555', color: '#555' }}>
                                            Can't make it
                                        </Button>
                                    </div>
                                    
                                    {/* Additional Actions */}
                                    <div className="flex gap-2 mt-2">
                                        <Button
                                            startIcon={<CiCalendar />}
                                            variant="text"
                                            sx={{ color: '#555' }}
                                        >
                                            Add to Calendar
                                        </Button>
                                        <Button
                                            startIcon={<FaRegRegistered />}
                                            variant="text"
                                            sx={{ color: '#555' }}
                                        >
                                            Register
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            {/* Upcoming Events Section */}
                            <div className="max-w-md mx-auto p-4 space-y-4">
                                {/* Month Navigation */}
                                <div className="bg-white rounded-lg shadow p-4 text-center">
                                    <div className="flex justify-between items-center mb-4">
                                    <button onClick={handlePreviousMonth} className="text-gray-500 hover:text-gray-700">
                                        <FaChevronLeft />
                                    </button>
                                    <h3 className="text-lg font-semibold">
                                        {currentDate.format("MMMM YYYY")}
                                    </h3>
                                    <button onClick={handleNextMonth} className="text-gray-500 hover:text-gray-700">
                                        <FaChevronRight />
                                    </button>
                                    </div>
                                    {/* Days of the Week */}
                                    <div className="grid grid-cols-7 text-xs text-gray-500 mb-2">
                                    <span className="text-blue-500">Mon</span>
                                    <span className="text-blue-500">Tue</span>
                                    <span className="text-blue-500">Wed</span>
                                    <span className="text-blue-500">Thu</span>
                                    <span className="text-blue-500">Fri</span>
                                    <span className="text-blue-500">Sat</span>
                                    <span className="text-blue-500">Sun</span>
                                    </div>
                                    {/* Dates Grid */}
                                    <div className="grid grid-cols-7 gap-1 text-center">
                                    {Array.from({ length: firstDayOfMonth }).map((_, i) => (
                                        <span key={`empty-${i}`} className="text-gray-300 text-sm">
                                        {/* Empty spaces before first day */}
                                        </span>
                                    ))}
                                    {Array.from({ length: daysInMonth }).map((_, i) => (
                                        <span
                                        key={i}
                                        className={`text-gray-700 text-sm ${
                                            (i + 1).toString() === today ? "bg-blue-500 text-white rounded-full" : ""
                                        }`}
                                        >
                                        {i + 1}
                                        </span>
                                    ))}
                                    </div>
                                </div>

                                {/* This Week Section */}
                                <div className="bg-white rounded-lg shadow p-4">
                                    <h4 className="text-lg font-semibold mb-3">This Week</h4>
                                    <div className="flex items-center justify-between mb-3">
                                    <button onClick={handlePreviousMonth} className="text-gray-500 hover:text-gray-700">
                                        <FaChevronLeft />
                                    </button>
                                    <div className="flex space-x-1">
                                        {weekDays.map((day, idx) => (
                                        <div
                                            key={idx}
                                            className={`w-8 h-8 flex items-center justify-center rounded ${
                                            day === today ? "bg-blue-500 text-white" : "text-gray-700"
                                            }`}
                                        >
                                            {day}
                                        </div>
                                        ))}
                                    </div>
                                    <button onClick={handleNextMonth} className="text-gray-500 hover:text-gray-700">
                                        <FaChevronRight />
                                    </button>
                                    </div>
                                    {/* Event Info */}
                                    <div className="flex items-center space-x-2">
                                    <div className="w-6 h-6 bg-blue-500 rounded-full flex items-center justify-center text-white">
                                        <FaUser />
                                    </div>
                                    <div>
                                        <h5 className="text-sm font-medium">Event Title</h5>
                                        <p className="text-xs text-gray-600">February 19</p>
                                    </div>
                                    </div>
                                </div>
                                </div>
                        </div>
                    </div>

                    {/* Upcoming Events */}
                    <div className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Upcoming Events</h2>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            {events.map((event, index) => (
                            <EventCard key={index} event={event} />
                            ))}
                        </div>
                    </div>

                    {/* Events Archive */}
                    <div>
                        <h2 className="text-2xl font-bold mb-4">Events Archive</h2>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            {events.map((event, index) => (
                            <EventCardArchive key={index} event={event} />
                            ))}
                        </div>
                    </div>
                </div>

            </>
        )
    }
    return (
        <>
            {activeTab == 'addEvent' ? <AddEvent /> : <CardList />}
        </>
    )
  }
export default EventList


