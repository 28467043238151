import React, { useState, useEffect } from "react";
import { Card, Typography } from "antd";

const { Paragraph } = Typography;

const TextThumbnail = () => {
  const [documentContent, setDocumentContent] = useState("");

  useEffect(() => {
    // Fetch the document content (you can replace with actual API or file)
    fetch(`${process.env.PUBLIC_URL}/sample.txt`)
      .then((response) => response.text())
      .then((text) => setDocumentContent(text));
  }, []);

  return (
    <Card className="h-full">
      <Typography>
        <Paragraph>{documentContent}</Paragraph>
      </Typography>
    </Card>
  );
};

export default TextThumbnail;
