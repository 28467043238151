import React, { useState } from "react";
import { Button, TextField, MenuItem, IconButton } from "@mui/material";
import { FiMapPin } from "react-icons/fi";
import { FaImage } from "react-icons/fa";
import { IoMdInformationCircleOutline } from "react-icons/io";
import MultipleUploadImages from "../../inputs/MultipleUploadImages";
import { BsPersonCheckFill } from "react-icons/bs";
import EmailRecipients from "../../inputs/EmailRecipients";
import { IoNewspaperOutline } from "react-icons/io5";
import { MdPersonAddAlt } from "react-icons/md";


const AddEvent = () => {

    // for multiple upload images
    const [image, setImage] = useState<string | null>(null);

    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file instanceof Blob) {
            setImage(URL.createObjectURL(file));
        }
    };

    const handleRemoveImage = () => {
        setImage(null);
    };

    // for register
    const [limitedParticipants, setLimitedParticipants] = useState(true);
    const [limitedPeriod, setLimitedPeriod] = useState(false);
    const [publishNow, setPublishNow] = useState(false);
    const [schedulePublish, setSchedulePublish] = useState(true);
    const [recipientsType, setRecipientsType] = useState("");

  return (
    <div className="flex h-screen p-8 pb-16">
        {/* Sidebar */}
        <aside className="w-1/4 bg-white p-6 border-r">
            <div className="mb-4">
                <h2 className="text-xl font-semibold">Add Event</h2>
                <p className="text-gray-500">Last update</p>
                <p className="text-gray-800 font-bold">Monday, June 06 | 06:42 AM</p>
                <p className="text-green-500 mt-1">Status: Draft</p>
            </div>
            <nav>
                <ul>
                    <li className="py-2 text-lg font-medium">CREATE EVENT</li>
                    <li className="py-1 text-gray-600">Upload Cover</li>
                    <li className="py-1 text-gray-600">Event Details</li>
                    <li className="py-1 text-gray-600">Location</li>
                    <li className="py-2 text-lg font-medium mt-4">PUBLISH EVENT</li>
                    <li className="py-1 text-gray-600">Registration</li>
                    <li className="py-1 text-gray-600">Recipients</li>
                    <li className="py-1 text-gray-600">Review and Publish</li>
                </ul>
            </nav>
        </aside>
        {/* End of sidebar */}

        {/* Main Content */}
        <main className="flex-1 p-8">
            {/* Upload Cover Section */}
            <h2 className="text-2xl font-semibold text-gray-800 flex items-center">
                <span className="mr-2 text-blue-600"><MdPersonAddAlt/></span> Upload cover
            </h2>
            <p className="text-gray-500 text-sm mt-1 mb-4">
                upload the event cover to capture you audience's attention
            </p>
            <div className="flex flex-col mb-4 items-center p-4 bg-gray-100 rounded-lg shadow-md">
                {image ? (
                    <div className="relative w-full max-w-sm">
                        <img
                            src={image}
                            alt="Uploaded Preview"
                            className="w-full h-auto rounded-md"
                        />
                        <div className="absolute top-2 right-2 flex space-x-2">
                            <button
                            onClick={handleRemoveImage}
                            className="px-2 py-1 bg-red-500 text-white rounded"
                            >
                            Remove
                            </button>
                            <label className="px-2 py-1 bg-blue-500 text-white rounded cursor-pointer">
                            Change
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageUpload}
                                className="hidden"
                            />
                            </label>
                        </div>
                    </div>
                ) : (
                    <label className="flex flex-col items-center justify-center w-full max-w-sm h-48 border-2 border-dashed border-gray-300 rounded-md cursor-pointer bg-white">
                    <span className="text-gray-500">Click to upload image</span>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        className="hidden"
                    />
                    </label>
                )}
            </div>

            {/* Event Details Section */}
            <section className="mb-8">
                <h2 className="text-2xl font-semibold flex items-center">
                    <span className="mr-2 text-xl font-semibold text-blue-600">
                        <IoMdInformationCircleOutline />
                    </span>
                        Event Details
                </h2>
            <TextField
                label="Name"
                variant="outlined"
                fullWidth
                margin="normal"
                placeholder="Rock Revolt: A Fusion of Power and Passion"
            />
            <TextField
                label="Description"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                placeholder="Provide essential event details"
                margin="normal"
            />
            <TextField
                select
                label="Category"
                variant="outlined"
                fullWidth
                margin="normal"
            >
                <MenuItem value="Music">Music</MenuItem>
                {/* Add more categories as needed */}
            </TextField>
            </section>

            {/* Album Section */}
            <section className="mb-8">
            <h2 className="text-2xl font-semibold">Album</h2>
            <MultipleUploadImages/>
            </section>

            {/* Date and Time Section */}
            <section className="mb-8">
            <h2 className="text-2xl font-semibold">Date and Time</h2>
            <div className="flex space-x-4 mt-4">
                <TextField
                label="Event Date"
                type="date"
                variant="outlined"
                defaultValue="2023-02-20"
                fullWidth
                InputLabelProps={{ shrink: true }}
                />
                <TextField
                label="Start Time"
                type="time"
                variant="outlined"
                defaultValue="06:00"
                fullWidth
                InputLabelProps={{ shrink: true }}
                />
                <TextField
                label="End Time"
                type="time"
                variant="outlined"
                defaultValue="11:00"
                fullWidth
                InputLabelProps={{ shrink: true }}
                />
            </div>
            </section>
            {/* Event Location */}
            <div className="space-y-4 bg-gray-50 pb-8">
                <h2 className="flex items-center text-2xl font-semibold">
                    <span role="img" aria-label="location" className="mr-2 text-blue-600"><FiMapPin /></span> Location
                </h2>

                <p className="text-gray-500 text-sm">
                    You can choose the location or pinpoint it on the map
                </p>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                    {/* Left Column: Form */}
                    <div className="space-y-4">
                    {/* Street Address */}
                    <div>
                        <label className="block text-gray-700 font-medium">Street Address</label>
                        <input
                        type="text"
                        placeholder="Address"
                        className="w-full px-3 py-2 mt-1 border rounded-lg focus:outline-none focus:border-blue-500 bg-gray-100 text-gray-700"
                        />
                    </div>

                    {/* Region */}
                    <div>
                        <label className="block text-gray-700 font-medium">Region</label>
                        <select
                        className="w-full px-3 py-2 mt-1 border rounded-lg focus:outline-none focus:border-blue-500 bg-gray-100 text-gray-700"
                        defaultValue="NCR"
                        >
                        <option>NCR</option>
                        <option>Region I</option>
                        <option>Region II</option>
                        </select>
                    </div>

                    {/* Province */}
                    <div>
                        <label className="block text-gray-700 font-medium">Province</label>
                        <select
                        className="w-full px-3 py-2 mt-1 border rounded-lg focus:outline-none focus:border-blue-500 bg-gray-100 text-gray-700"
                        defaultValue="Metro Manila"
                        >
                        <option>Metro Manila</option>
                        <option>Ilocos Norte</option>
                        <option>Pampanga</option>
                        </select>
                    </div>

                    {/* Country / Region */}
                    <div>
                        <label className="block text-gray-700 font-medium">Country / Region</label>
                        <select
                        className="w-full px-3 py-2 mt-1 border rounded-lg focus:outline-none focus:border-blue-500 bg-gray-100 text-gray-700"
                        defaultValue="Muntinlupa"
                        >
                        <option>Muntinlupa</option>
                        <option>Pasig</option>
                        <option>Quezon City</option>
                        </select>
                    </div>
                    </div>

                    {/* Right Column: Map */}
                    <div className="w-full h-full">
                    <iframe
                        title="Google Maps"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.104928554607!2d-122.42040648468092!3d37.77492927975902!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085809c3e508e2d%3A0xf7f43f5b9293d2a5!2sSan%20Francisco%2C%20CA%2C%20USA!5e0!3m2!1sen!2sph!4v1618935288348!5m2!1sen!2sph"
                        className="w-full h-64 md:h-full rounded-lg"
                        // allowFullScreen=""
                        loading="lazy"
                    ></iframe>
                    </div>
                </div>
            </div>
            {/* Register */}
            <form className="space-y-6">
                {/* Registration Section */}
                <div>
                    <h2 className="text-2xl font-semibold text-gray-800 flex items-center">
                        <span className="mr-2 text-blue-600"><BsPersonCheckFill /></span> Registration
                    </h2>
                    <p className="text-gray-500 text-sm mt-1 mb-4">
                        Provide the registration settings
                    </p>

                    <div className="flex space-x-16 mb-2">
                        <div>
                            <label className="flex text-gray-700 ">
                                <input
                                type="checkbox"
                                checked={!limitedParticipants}
                                onChange={() => setLimitedParticipants(false)}
                                className="form-checkbox text-blue-600 focus:ring-0 focus:ring-offset-0"
                                />
                                <span className="ml-2">Unlimited Participants</span>
                            </label>
                        </div>
                        <div>
                            <label className="flex items-center text-gray-700">
                                <input
                                type="checkbox"
                                checked={limitedParticipants}
                                onChange={() => setLimitedParticipants(true)}
                                className="form-checkbox text-blue-600 focus:ring-0 focus:ring-offset-0"
                                />
                                <span className="ml-2">Limited Participants</span>
                            </label>
                            {limitedParticipants && (
                                <div className="flex items-center mt-1 ml-8">
                                    <span className="text-gray-700 mr-2">Limited to</span>
                                    <input
                                    type="number"
                                    className="border rounded-md w-20 p-1 text-center border-gray-300 focus:border-blue-500 focus:outline-none"
                                    defaultValue="100"
                                    />
                                    <span className="ml-2 text-gray-700">participants</span>
                                </div>
                                )}
                        </div>
                    </div>

                    <div className="flex space-x-4 mt-4">
                        <div className="space-y-2 py-8">
                            <label className="flex items-center text-gray-700">
                                <input
                                    type="checkbox"
                                    checked={!limitedPeriod}
                                    onChange={() => setLimitedPeriod(false)}
                                    className="form-checkbox text-blue-600 focus:ring-0 focus:ring-offset-0"
                                />
                                <span className="ml-2">Until full capacity</span>
                            </label>
                        </div>
                        <div className="space-y-2 py-8">
                            <label className="flex items-center text-gray-700">
                                <input
                                type="checkbox"
                                checked={limitedPeriod}
                                onChange={() => setLimitedPeriod(true)}
                                className="form-checkbox text-blue-600 focus:ring-0 focus:ring-offset-0"
                                />
                                <span className="ml-2">Limited period</span>
                            </label>
                            {limitedPeriod && (
                                <div className="flex items-center mt-2 ml-8">
                                    <input
                                    type="number"
                                    className="border rounded-md w-20 p-1 text-center border-gray-300 focus:border-blue-500 focus:outline-none"
                                    defaultValue="3"
                                    />
                                    <span className="ml-2 text-gray-700">Hours before the event starts</span>
                                </div>
                                )}
                        </div>
                    </div>
                </div>

                {/* Recipients Section */}
                <div>
                    <h2 className="text-2xl font-semibold text-gray-800 flex items-center">
                        <span className="mr-2 text-blue-600"><MdPersonAddAlt/></span> Recipients
                    </h2>
                    <p className="text-gray-500 text-sm mt-1 mb-4">
                        Select users to invite to this event
                    </p>

                    <div className="space-y-2 py-4">
                        <EmailRecipients/>
                        {/* <label className="flex items-center text-gray-700">
                            <input
                                type="checkbox"
                                checked={recipientsType === "everyone"}
                                onChange={() => setRecipientsType("everyone")}
                                className="form-checkbox text-blue-600 focus:ring-0 focus:ring-offset-0"
                            />
                            <span className="ml-2">Everyone</span>
                        </label>

                        <label className="flex items-center text-gray-700">
                            <input
                                type="checkbox"
                                checked={recipientsType === "individuals"}
                                onChange={() => setRecipientsType("individuals")}
                                className="form-checkbox text-blue-600 focus:ring-0 focus:ring-offset-0"
                            />
                            <span className="ml-2">Selected individuals</span>
                        </label>

                        {recipientsType === "individuals" && (
                            <input
                                type="text"
                                placeholder="Search"
                                className="border rounded-md p-2 w-full border-gray-300 focus:border-blue-500 focus:outline-none"
                            />
                        )}

                        <label className="flex items-center text-gray-700">
                            <input
                                type="checkbox"
                                checked={recipientsType === "groups"}
                                onChange={() => setRecipientsType("groups")}
                                className="form-checkbox text-blue-600 focus:ring-0 focus:ring-offset-0"
                            />
                            <span className="ml-2">Selected groups</span>
                        </label>

                            */}
                    </div>
                </div>

                {/* Publish Date Section */}
                <div>
                    <h2 className="text-2xl font-semibold text-gray-800 flex items-center">
                        <span className="mr-2 text-blue-600"><IoNewspaperOutline /></span> Publish Date
                    </h2>
                    <p className="text-gray-500 text-sm mt-1 mb-4">
                        Provide the date of publish of the event
                    </p>

                    <div className="flex space-x-4">
                        <div className="space-y-2 py-4">
                            <label className="flex  text-gray-700">
                                <input
                                type="checkbox"
                                checked={publishNow}
                                onChange={() => setPublishNow(!publishNow)}
                                className="form-checkbox text-blue-600 focus:ring-0 focus:ring-offset-0"
                                />
                                <span className="ml-2">Publish now</span>
                            </label>
                        </div>
                        <div className="space-y-2 py-4">
                            <label className="flex text-gray-700">
                                <input
                                type="checkbox"
                                checked={schedulePublish}
                                onChange={() => setSchedulePublish(!schedulePublish)}
                                className="form-checkbox text-blue-600 focus:ring-0 focus:ring-offset-0"
                                />
                                <span className="ml-2">Schedule publish</span>
                            </label>
                            {schedulePublish && (
                                <div className="flex items-center space-x-4 mt-2">
                                    <input
                                        type="date"
                                        className="border rounded-md p-2 border-gray-300 focus:border-blue-500 focus:outline-none"
                                        defaultValue="2023-02-20"
                                    />
                                    <input
                                        type="time"
                                        className="border rounded-md p-2 border-gray-300 focus:border-blue-500 focus:outline-none"
                                        defaultValue="08:00"
                                    />
                                </div>
                            )}
                        </div>
                        <hr/>
                    </div>

                    {/* Action Buttons */}
                    <div className="flex items-center space-x-4 mt-6 justify-center">
                        <button
                            type="submit"
                            className="bg-green-600 text-white py-2 px-6 rounded font-semibold hover:bg-green-700"
                            >
                            Publish
                        </button>
                        <button
                            type="button"
                            className="bg-gray-200 text-gray-700 py-2 px-6 rounded font-semibold hover:bg-gray-300"
                            >
                            Cancel
                        </button>
                    </div>
                </div>
            </form>
        </main>
    </div>
  );
};

export default AddEvent;
