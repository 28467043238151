import { useState } from "react";
import Header from "../reuseables/Header";
import {
  PaginationProps,
  Typography,
  Space,
} from "antd";
import { useFetchNewsPageQuery } from "../../store";
import LoadingPage from "../reuseables/LoadingPage";
import ErrorPage from "../reuseables/ErrorPage";
import {
  CaretDownFilled,
  FileExcelFilled,
  FileFilled,
  FileImageFilled,
  FilePdfFilled,
  FilePptFilled,
  FileWordFilled,
} from "@ant-design/icons";
import { GlobalType } from "../Types";
import MemoTabs from "./WhatsNew/MemoTabs";
import EventTabs from "./WhatsNew/EventTabs";


interface FileType {
  file: string;
  id: number;
}

interface objType extends GlobalType<string> {
  title: string;
  description: string;
  date_uploaded: string;
  ftp: FileType[];
}

export interface TabType {
  label: string;
  value: number;
  component?: JSX.Element;
}

const WhatsNew = () => {
  const [pageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [dataStyle, setDataSyle] = useState(1);


  const [activeTab, setActiveTab] = useState<TabType>({
    label: "All",
    value: 1,
    component: <MemoTabs/>,
  });

  const tabs: TabType[] = [
    {
      label: "Memos",
      value: 1,
      component: <MemoTabs/>,
    },
    {
      label: "Events",
      value: 2,
      component: <EventTabs/>
    }
  ];

  // handle change for tabs
  const handleChange = (tab: TabType) => {
    setActiveTab(tab);
  };

  console.log(dataStyle)
  const newApi = useFetchNewsPageQuery({
    page,
    pageSize,
    search,
  });

  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  // return a file name
  // const detectFileName = (fileName: string) => {
  //   return fileName.split("/").pop();
  // };

  // // return file type
  // const detectFileType = (fileName: string) => {
  //   const extension = fileName.split(".").pop();

  //   const iconMap: { [key: string]: JSX.Element } = {
  //     docx: <FileWordFilled className="text-blue-500" />,
  //     ppt: <FilePptFilled className="text-orange-600" />,
  //     pdf: <FilePdfFilled className="text-red-600" />,
  //     xlsx: <FileExcelFilled className="text-green-700" />,
  //     xls: <FileExcelFilled className="text-green-700" />,
  //   };
  //   return (
  //     iconMap[extension as keyof typeof iconMap] || (
  //       <FileFilled className="text-neutral-800" />
  //     )
  //   );
  // };

  // const dataAccordion = newApi.data?.results.map(
  //   ({ id, title, description, date_uploaded, ftp }: objType) => ({
  //     key: id,
  //     showArrow: false,
  //     label: (
  //       <Space className=" flex justify-between items-center">
  //         <Space className="space-x-5">
  //           <FileImageFilled className="text-[#cfd413] text-2xl" />
  //           <Space className=" block">
  //             <Typography.Text className=" font-sans font-semibold">
  //               {title}
  //             </Typography.Text>
  //             <Typography.Text className=" font-sans text-xs text-neutral-600">
  //               {date_uploaded}
  //             </Typography.Text>
  //           </Space>
  //           <Typography.Text className="text-neutral-800 font-sans border-l-2 ml-10 pl-8 border-neutral-600">
  //             {description}
  //           </Typography.Text>
  //         </Space>

  //         <CaretDownFilled className=" text-orange-600" />
  //       </Space>
  //     ),
  //     children: ftp.map(({ file, id }) => {
  //       const fileName = detectFileName(file);
  //       const fileType = detectFileType(file);
  //       return (
  //         <>
  //           <Space className="ml-10">
  //             <Space key={id} className=" flex items-center">
  //               {fileType}
  //               <Typography.Link
  //                 className="font-sans"
  //                 href={apiDomain?.concat("", file)}
  //                 target="_blank"
  //                 download={apiDomain?.concat("", file)}
  //               >
  //                 {fileName}
  //               </Typography.Link>
  //             </Space>
  //           </Space>
  //         </>
  //       );
  //     }),
  //   })
  // );

  return (
    // <>
    //   {/* {newApi.isLoading && <LoadingPage />}
    //   {newApi.error && <ErrorPage />}
    //   {newApi.data && ( */}
        <>
          <Header>
          <div className="p-4 flex justify-between items-center w-full">
            <div className="flex items-center space-x-5">
              <Header.Title title="What's New!" />
              <Header.Image src="../images/NewsImg.svg" />
            </div>
            <div className="space-x-4">
              {tabs.map((tab) => (
                <button
                  key={tab.value}
                  className={`pb-2 font-semibold ${activeTab.value === tab.value ? 'text-black border-b-2 border-black' : 'text-gray-500'}`}
                  onClick={() => handleChange(tab)}
                >
                  {tab.label}
                </button>
              ))}
            </div>
          </div>
          </Header>
          <div className="mt-4">
            {activeTab.component}
          </div>
        </>
    //   )}
    // </>
  );
};

export default WhatsNew;
