import React, { useState} from 'react'
import { BsSearch } from "react-icons/bs";

import {
    Input,
    Pagination,
    PaginationProps,
  } from "antd";
import CustomContainer from '../../reuseables/CustomContainer';
import { ImParagraphLeft } from "react-icons/im";
import { FaBorderAll } from "react-icons/fa6";
// import AddNews from '../../modals/AddNews';
import { useFetchNewsPageQuery } from '../../../store';
import CardMemos from './CardMemos';
import TableMemo from './TableMemo';
import AddMemo from '../../modals/WhatsNew/AddMemo';


const MemoTabs = () => {

    const [pageSize] = useState(10);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const handleSearchChange = (event: any) => {
        setSearch(event.target.value);
        setPage(1);
    };
    const [dataStyle, setDataSyle] = useState(1);

    // function for change the page
    const onChangePage: PaginationProps["onChange"] = (page) => {
        setPage(page);
    };


    const updateDataStyle = (val: number) => {
        setDataSyle(val);
    };
    const newApi = useFetchNewsPageQuery({
        page,
        pageSize,
        search,
    });

    return (
        <CustomContainer>
            <div className=" mt-10 space-y-5">
                <div className=" flex items-center justify-between">
                    <div className="flex gap-2">
                        <Input
                            placeholder="Search.."
                            defaultValue={search}
                            onKeyUp={handleSearchChange}
                            className="custom-style-input w-72"
                            suffix={<BsSearch />}
                        />
                        { dataStyle === 1 ? (
                            <button onClick={()=> updateDataStyle(2)}>
                            <ImParagraphLeft />
                            </button>
                            ) : (
                            <button onClick={()=> updateDataStyle(1)}>
                                <FaBorderAll />
                            </button>
                            )
                        }
                    </div>
                    <AddMemo/>
                    {/* <AddNews /> */}
                </div>
                {/* {dataAccordion.length === 0 ? (
                    <Empty className=" border-t border-b border-neutral-200 py-10" />
                ) : ( */}
                    {/* <>
                    <Collapse accordion items={dataAccordion}></Collapse>
                    </> */}
                {/* )} */}
                { dataStyle === 1 ? ( <CardMemos/>) : ( <TableMemo/>)}
                <Pagination
                    className=" flex justify-end"
                    current={page}
                    onChange={onChangePage}
                    total={newApi.data?.count}
                    defaultPageSize={pageSize}
                />
            </div>
        </CustomContainer>
    )

}
export default MemoTabs;