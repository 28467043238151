import { FilterOutlined } from "@ant-design/icons";
import { Button, Input, Popover, Typography } from "antd";
import { BsSearch } from "react-icons/bs";
import { MdOutlineEventNote } from "react-icons/md";
import EmployeeMngmentExport from "../../reuseables/EmployeeMngmentExport";
import CustomedCalendar from "../../reuseables/CustomedCalendar";

import CustomedWeekDates from "./EmployeeAttendance/CustomedWeekDates";
import { useState } from "react";
import ThisWeekCard from "./EmployeeAttendance/ThisWeekCard";
import { empListAttendance } from "./SampleDatas";
import EmpAttendanceTable from "./EmployeeAttendance/EmpAttendanceTable";
import EmpAppDtls from "./EmployeeAttendance/EmpAppDtls";
import Item from "antd/es/list/Item";

export default function EmpAttendance() {
  const { Text } = Typography;
  const [empId, setEmpId] = useState<number>(0);
  const [openDtl, setOpenDtl] = useState<boolean>(false);
  const sampleData = empListAttendance;
  const [category, setCategory] = useState({
    name: "Leave and Absence",
    id: 1,
  });

  const handleChangeCat = (name: string, id: number) =>
    setCategory({ name, id });

  const handleSelectProfile = (open: boolean) => setOpenDtl(open);
  return (
    <div className="mt-10 space-y-5">
      <div className="grid grid-cols-2 gap-60">
        <div className="flex flex-col gap-5">
          <div className="flex gap-2 items-center">
            <div className="text-[#0156D2FF] text-5xl">
              <MdOutlineEventNote />
            </div>
            <p className="flex flex-col font-semibold text-2xl">
              <span>Attendance</span>
            </p>
          </div>
          <p className="pl-2 text-3xl font-semibold">
            <span>{category.name}</span>
          </p>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex gap-2">
            <Button
              className="font-semibold"
              onClick={() => handleChangeCat("Leave and Absence", 1)}
            >
              Leave and Absence
            </Button>
            <Button
              className="font-semibold"
              onClick={() => handleChangeCat("Timesheet", 2)}
            >
              Timesheet
            </Button>
          </div>
          <div className="flex gap-2">
            <Button
              className="font-semibold"
              onClick={() => handleChangeCat("Approved", 3)}
            >
              Approved
            </Button>
            <Button
              className="font-semibold"
              onClick={() => handleChangeCat("Request", 4)}
            >
              Request
            </Button>
            <Button
              className="font-semibold"
              onClick={() => handleChangeCat("Credits", 5)}
            >
              Credits
            </Button>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2"></div>
      <div className="grid grid-cols-5 gap-7">
        <div className="col-span-3 mt-4 grid grid-rows-2 gap-40">
          <div>
            {/* Input search and filter export buttons */}
            <div className="mt-2 flex justify-between">
              <Input
                placeholder="Search.."
                className="custom-style-input w-72 bgcy"
                suffix={<BsSearch />}
              />
              <div className="flex space-x-5 mr-4">
                <Popover
                  content={<Text className=" font-sans">Clear filter</Text>}
                >
                  <FilterOutlined className="text-base cursor-pointer text-[#0056d2] shadow hover:ring-1 hover:ring-[#0056d2] shadow-neutral-300 rounded-full p-2" />
                </Popover>
                <EmployeeMngmentExport />
              </div>
            </div>
            {/* Input search and filter export buttons end */}
            <EmpAttendanceTable
              data={sampleData}
              empId={setEmpId}
              openProfile={handleSelectProfile}
            />
          </div>
        </div>
        <div className="flex flex-col gap-4 col-span-2">
          {openDtl ? (
            <EmpAppDtls id={empId} openProfile={handleSelectProfile} />
          ) : (
            <>
              <CustomedCalendar />
              <div className="bg-white p-4">
                <CustomedWeekDates />
                <div className="flex flex-col gap-4 items-start pl-4 mt-2">
                  {sampleData.map((item, index) => (
                    <ThisWeekCard
                      key={item.id}
                      profile={item.profile}
                      firstName={item.firstName}
                      lastName={item.lastName}
                      leaveType={item.leaveType}
                    />
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
