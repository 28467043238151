interface CardInfo {
  id: number;
  position: string;
  name: string;
  department: string;
}

const hrDepartment: CardInfo[] = [
  { id: 1, position: "Head of HR", name: "Anne Go", department: "HR" },
  { id: 2, position: "HR Manager", name: "John Doe", department: "HR" },
  { id: 3, position: "HR Coordinator", name: "Mary Smith", department: "HR" },
  { id: 4, position: "HR Specialist", name: "Eve Johnson", department: "HR" },
  { id: 5, position: "Recruiter", name: "Chris Brown", department: "HR" },
  {
    id: 6,
    position: "Recruitment Assistant",
    name: "Alice Tan",
    department: "HR",
  },
  {
    id: 7,
    position: "Benefits Coordinator",
    name: "Laura White",
    department: "HR",
  },
  { id: 8, position: "Training Specialist", name: "Sam Lee", department: "HR" },
];

const financeDepartment: CardInfo[] = [
  {
    id: 5,
    position: "Head of Finance",
    name: "Sherry Sy",
    department: "Finance",
  },
  {
    id: 6,
    position: "Finance Manager",
    name: "Kevin Wong",
    department: "Finance",
  },
  {
    id: 7,
    position: "Senior Financial Analyst",
    name: "Lisa Green",
    department: "Finance",
  },
  {
    id: 8,
    position: "Financial Analyst",
    name: "Tom Harris",
    department: "Finance",
  },
  {
    id: 9,
    position: "Accounts Payable Specialist",
    name: "Linda Scott",
    department: "Finance",
  },
  {
    id: 10,
    position: "Accounts Receivable Specialist",
    name: "Peter Young",
    department: "Finance",
  },
  {
    id: 11,
    position: "Payroll Coordinator",
    name: "Susan Kim",
    department: "Finance",
  },
  {
    id: 12,
    position: "Billing Specialist",
    name: "Rachel Adams",
    department: "Finance",
  },
];

const directorTeam: CardInfo[] = [
  {
    id: 6,
    position: "Head of Director",
    name: "Albert Go",
    department: "Director",
  },
  {
    id: 7,
    position: "Operations Director",
    name: "Brenda Liu",
    department: "Director",
  },
  {
    id: 8,
    position: "Sales Director",
    name: "Carlos Mendoza",
    department: "Director",
  },
  {
    id: 9,
    position: "Marketing Director",
    name: "Dana Chen",
    department: "Director",
  },
  {
    id: 10,
    position: "IT Director",
    name: "Eric Zhang",
    department: "Director",
  },
  {
    id: 11,
    position: "HR Director",
    name: "Fiona Green",
    department: "Director",
  },
  {
    id: 12,
    position: "Assistant to Director",
    name: "George White",
    department: "Director",
  },
];

const salesDepartment: CardInfo[] = [
  { id: 7, position: "Head of Sales", name: "Abet Lou", department: "Sales" },
  {
    id: 8,
    position: "Regional Sales Manager",
    name: "Ben Cruz",
    department: "Sales",
  },
  {
    id: 9,
    position: "Sales Supervisor",
    name: "Carla Reyes",
    department: "Sales",
  },
  {
    id: 10,
    position: "Sales Representative",
    name: "Diana Tan",
    department: "Sales",
  },
  {
    id: 11,
    position: "Sales Representative",
    name: "Eddie Chua",
    department: "Sales",
  },
  {
    id: 12,
    position: "Sales Assistant",
    name: "Faye Lim",
    department: "Sales",
  },
  {
    id: 13,
    position: "Account Executive",
    name: "Gina Ortega",
    department: "Sales",
  },
];

const productsDepartment: CardInfo[] = [
  {
    id: 8,
    position: "Head of Products",
    name: "Kevin Cruz",
    department: "Products",
  },
  {
    id: 9,
    position: "Product Manager",
    name: "Liza Martinez",
    department: "Products",
  },
  {
    id: 10,
    position: "Product Development Lead",
    name: "Miguel Santos",
    department: "Products",
  },
  {
    id: 11,
    position: "Product Designer",
    name: "Nina Garcia",
    department: "Products",
  },
  {
    id: 12,
    position: "Product Analyst",
    name: "Oscar Perez",
    department: "Products",
  },
  {
    id: 13,
    position: "Quality Assurance Specialist",
    name: "Patricia Young",
    department: "Products",
  },
  {
    id: 14,
    position: "UX/UI Designer",
    name: "Quinn Taylor",
    department: "Products",
  },
  {
    id: 15,
    position: "Junior Product Analyst",
    name: "Ronald Lee",
    department: "Products",
  },
];

const marketingDepartment: CardInfo[] = [
  {
    id: 9,
    position: "Content Marketing",
    name: "Pokoy Kie",
    department: "Marketing",
  },
  {
    id: 10,
    position: "Social Media Manager",
    name: "Sarah Lim",
    department: "Marketing",
  },
  {
    id: 11,
    position: "SEO Specialist",
    name: "Tommy Chen",
    department: "Marketing",
  },
  {
    id: 12,
    position: "Content Writer",
    name: "Uma Dela Cruz",
    department: "Marketing",
  },
  {
    id: 13,
    position: "Graphic Designer",
    name: "Victor Ng",
    department: "Marketing",
  },
  {
    id: 14,
    position: "Digital Marketing Analyst",
    name: "Wendy Garcia",
    department: "Marketing",
  },
  {
    id: 15,
    position: "Video Editor",
    name: "Xander Lee",
    department: "Marketing",
  },
  {
    id: 16,
    position: "Junior Content Writer",
    name: "Yana Santos",
    department: "Marketing",
  },
];

const marketingExecutiveTeam: CardInfo[] = [
  {
    id: 10,
    position: "Marketing Executive",
    name: "Alben Burja",
    department: "Marketing",
  },
  {
    id: 11,
    position: "Brand Manager",
    name: "Bella Santos",
    department: "Marketing",
  },
  {
    id: 12,
    position: "Public Relations Specialist",
    name: "Carlos Rivera",
    department: "Marketing",
  },
  {
    id: 13,
    position: "Market Research Analyst",
    name: "Daisy Tan",
    department: "Marketing",
  },
  {
    id: 14,
    position: "Campaign Manager",
    name: "Edward Li",
    department: "Marketing",
  },
  {
    id: 15,
    position: "Content Strategist",
    name: "Fiona Gonzales",
    department: "Marketing",
  },
  {
    id: 16,
    position: "Marketing Coordinator",
    name: "Gina Torres",
    department: "Marketing",
  },
  {
    id: 17,
    position: "Events Specialist",
    name: "Henry Lee",
    department: "Marketing",
  },
];

const socialMarketingDepartment: CardInfo[] = [
  {
    id: 11,
    position: "Social Marketing",
    name: "Kokoy Bugoy",
    department: "Marketing",
  },
  {
    id: 12,
    position: "Social Media Strategist",
    name: "Lara Mendoza",
    department: "Marketing",
  },
  {
    id: 13,
    position: "Community Manager",
    name: "Marco Santos",
    department: "Marketing",
  },
  {
    id: 14,
    position: "Influencer Coordinator",
    name: "Nina Park",
    department: "Marketing",
  },
  {
    id: 15,
    position: "Content Creator",
    name: "Oscar Diaz",
    department: "Marketing",
  },
  {
    id: 16,
    position: "Social Media Analyst",
    name: "Paula Reyes",
    department: "Marketing",
  },
  {
    id: 17,
    position: "Junior Community Manager",
    name: "Quincy Yu",
    department: "Marketing",
  },
  {
    id: 18,
    position: "Video Content Specialist",
    name: "Rico Martinez",
    department: "Marketing",
  },
];

export const departmentEmployees = (department: string) => {
  if (department === "HR") {
    return hrDepartment;
  } else if (department === "Finance") {
    return financeDepartment;
  } else if (department === "Director") {
    return directorTeam;
  } else if (department === "Sales") {
    return salesDepartment;
  } else if (department === "Products") {
    return productsDepartment;
  } else if (department === "Marketing") {
    return marketingDepartment;
  } else if (department === "Marketing Executive") {
    return marketingExecutiveTeam;
  } else if (department === "Social Marketing") {
    return socialMarketingDepartment;
  } else {
    return [];
  }
};

type Attendance = {
  id: number;
  lastName: string;
  firstName: string;
  leaveType: number;
  paid: boolean;
  status: 1 | 2 | 3;
  profile: string;
};

export const empListAttendance: Attendance[] = [
  {
    id: 1,
    lastName: "Johnson",
    firstName: "Emily",
    leaveType: 2,
    paid: true,
    status: 1,
    profile: "https://randomuser.me/api/portraits/women/1.jpg",
  },
  {
    id: 2,
    lastName: "Brown",
    firstName: "Michael",
    leaveType: 1,
    paid: false,
    status: 2,
    profile: "https://randomuser.me/api/portraits/men/2.jpg",
  },
  {
    id: 3,
    lastName: "Williams",
    firstName: "Sarah",
    leaveType: 3,
    paid: true,
    status: 3,
    profile: "https://randomuser.me/api/portraits/women/3.jpg",
  },
  {
    id: 4,
    lastName: "Jones",
    firstName: "David",
    leaveType: 2,
    paid: false,
    status: 1,
    profile: "https://randomuser.me/api/portraits/men/4.jpg",
  },
  {
    id: 5,
    lastName: "Garcia",
    firstName: "Anna",
    leaveType: 1,
    paid: true,
    status: 2,
    profile: "https://randomuser.me/api/portraits/women/5.jpg",
  },
  {
    id: 6,
    lastName: "Martinez",
    firstName: "Chris",
    leaveType: 3,
    paid: true,
    status: 3,
    profile: "https://randomuser.me/api/portraits/men/6.jpg",
  },
  {
    id: 7,
    lastName: "Davis",
    firstName: "Jessica",
    leaveType: 2,
    paid: false,
    status: 2,
    profile: "https://randomuser.me/api/portraits/women/7.jpg",
  },
  {
    id: 8,
    lastName: "Miller",
    firstName: "Tom",
    leaveType: 1,
    paid: true,
    status: 1,
    profile: "https://randomuser.me/api/portraits/men/8.jpg",
  },
  {
    id: 9,
    lastName: "Hernandez",
    firstName: "Sofia",
    leaveType: 3,
    paid: false,
    status: 3,
    profile: "https://randomuser.me/api/portraits/women/9.jpg",
  },
  {
    id: 10,
    lastName: "Rodriguez",
    firstName: "Juan",
    leaveType: 2,
    paid: true,
    status: 2,
    profile: "https://randomuser.me/api/portraits/men/10.jpg",
  },
];

export const empListLeaveHistory = [
  {
    id: 1,
    date: "04, Jul",
    leaveType: 1,
    profile: "https://randomuser.me/api/portraits/women/1.jpg",
  },
  {
    id: 2,
    date: "04, Jul",
    leaveType: 1,
    profile: "https://randomuser.me/api/portraits/men/2.jpg",
  },
  {
    id: 3,
    date: "21, Sep",
    leaveType: Math.floor(Math.random() * 5) + 1,
    profile: "https://randomuser.me/api/portraits/women/3.jpg",
  },
  {
    id: 4,
    date: "05, Oct",
    leaveType: Math.floor(Math.random() * 5) + 1,
    profile: "https://randomuser.me/api/portraits/men/4.jpg",
  },
  {
    id: 5,
    date: "12, Nov",
    leaveType: Math.floor(Math.random() * 5) + 1,
    profile: "https://randomuser.me/api/portraits/women/5.jpg",
  },
  {
    id: 6,
    date: "19, Dec",
    leaveType: Math.floor(Math.random() * 5) + 1,
    profile: "https://randomuser.me/api/portraits/men/6.jpg",
  },
  {
    id: 7,
    date: "25, Jan",
    leaveType: Math.floor(Math.random() * 5) + 1,
    profile: "https://randomuser.me/api/portraits/women/7.jpg",
  },
  {
    id: 8,
    date: "02, Feb",
    leaveType: Math.floor(Math.random() * 5) + 1,
    profile: "https://randomuser.me/api/portraits/men/8.jpg",
  },
];
