import React, { useState } from "react";
import moment, { Moment } from "moment";
import { Button } from "antd";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";

const CustomedWeekDates: React.FC = () => {
  // State to keep track of the current week
  const [currentWeekStart, setCurrentWeekStart] = useState<moment.Moment>(
    moment().startOf("isoWeek")
  );

  // Get the weekdays with their respective dates (starting from Monday)
  const getWeekDays = (startOfWeek: moment.Moment) => {
    const days = [];
    // Use isoWeek to start the week from Monday
    const startOfMondayWeek = startOfWeek.clone().startOf("isoWeek");

    for (let i = 0; i < 7; i++) {
      const day = startOfMondayWeek.clone().add(i, "days");
      // days.push(startOfMondayWeek.clone().add(i, "days"));
      if (day.day() !== 0 && day.day() !== 6) {
        days.push(day);
      }
    }
    return days;
  };

  // Navigate to the previous week
  const goToPreviousWeek = () => {
    setCurrentWeekStart(currentWeekStart.clone().subtract(1, "week"));
  };

  // Navigate to the next week
  const goToNextWeek = () => {
    setCurrentWeekStart(currentWeekStart.clone().add(1, "week"));
  };

  // Get the current week days
  const weekdays = getWeekDays(currentWeekStart);

  return (
    <div className="flex flex-col">
      <div className="mb-4 w-full max-w-lg text-start">
        <span className="font-semibold text-xl">THIS WEEK</span>
      </div>

      {/* Weekdays and Dates */}
      <div className="flex space-x-4 py-2 items-center justify-evenly">
        <Button type="text" onClick={goToPreviousWeek} className="px-2 py-4">
          <MdOutlineArrowBackIos className="text-lg font-semibold" />
        </Button>
        <div className="flex gap-4">
          {weekdays.map((day, index) => {
            const today =
              new Date().toDateString() === day.toDate().toDateString();
            return (
              <div
                key={index}
                className={`flex flex-col items-center text-center p-4 ${
                  today
                    ? "border-2 border-cyan-500 rounded-md text-xl scale-100 bg-cyan-100"
                    : "bg-gray-50"
                }`}
                style={{ minWidth: "80px" }}
              >
                <div className={`text-lg ${today ? "text-cyan-700" : ""}`}>
                  {day.date()}
                </div>
                <div className={`${today ? "font-semibold" : ""}`}>
                  {day.format("ddd")}
                </div>
              </div>
            );
          })}
        </div>
        <Button type="text" onClick={goToNextWeek} className="px-2 py-4">
          <MdOutlineArrowForwardIos className="text-lg font-semibold" />
        </Button>
      </div>
    </div>
  );
};

export default CustomedWeekDates;
