import React from "react";

type ProfileProps = {
  profile: string;
};
export default function EmployeeIcon({ profile }: ProfileProps) {
  return (
    <div className=" flex justify-center items-center">
      <img
        src={profile}
        className="aspect-square object-fill w-20 rounded-full"
        alt={profile}
      />
    </div>
  );
}
