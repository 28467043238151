import React, { useState } from 'react';
import { Dialog, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const EventView = () => {
  const [open, setOpen] = useState(false);

  // Open and close modal functions
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      {/* Button to Open Modal */}
      <button onClick={handleOpen} className="bg-yellow-500 text-white px-4 py-2 rounded-md">
        Open Event
      </button>

      {/* Full-Screen Modal */}
      <Dialog open={open} onClose={handleClose} fullScreen>

        {/* Modal Content */}
        <div className="bg-gray-50 min-h-screen text-gray-800 font-sans">
          {/* Header */}
          <header className="bg-white shadow p-4 flex items-center justify-between">
          </header>

          {/* Event Banner */}
          <section className="relative">
            <img src="/images/whatsNew/event1.jpg" alt="Event Banner" className="w-full h-80 object-cover" />
            <div className="absolute inset-0 flex flex-col justify-center items-center bg-black bg-opacity-50">
              <h3 className="text-3xl font-bold text-white">Rock Revolt: A Fusion of Power and Passion</h3>
              <p className="text-white text-sm mt-2">February 20, 2024 | 5:00 PM - 9:00 PM</p>
              <span className="mt-4 px-4 py-2 bg-red-600 text-white rounded-full">Anonymous Rooster</span>
            </div>
          </section>

          {/* Timing and Location Section */}
          <section className="p-6">
            <h3 className="text-2xl font-bold text-gray-800">Timing and location</h3>
            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="flex items-start space-x-3">
                <div className="text-blue-500">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 4h10M4 21h16M4 10h16m-9 4h10" />
                  </svg>
                </div>
                <div>
                  <h4 className="text-lg font-semibold">Date and Time</h4>
                  <p>Saturday, February 20, 8:00 PM</p>
                </div>
              </div>
              <div className="flex items-start space-x-3">
                <div className="text-blue-500">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.121 5.121a5 5 0 017.07 0l.707.707a5 5 0 017.07 0l1.414 1.414a5 5 0 010 7.07l-7.07 7.07a5 5 0 01-7.07 0l-7.07-7.07a5 5 0 010-7.07l1.414-1.414a5 5 0 017.07 0l.707.707z" />
                  </svg>
                </div>
                <div>
                  <h4 className="text-lg font-semibold">Location</h4>
                  <p>Central Park, New York, NY 10022, United States</p>
                </div>
              </div>
            </div>

            {/* Map */}
            <div className="mt-6">
              {/* <img src="https://via.placeholder.com/600x300" alt="Map" className="w-full h-64 object-cover rounded-lg shadow-md" /> */}
              <iframe
                    title="Google Maps"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.104928554607!2d-122.42040648468092!3d37.77492927975902!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085809c3e508e2d%3A0xf7f43f5b9293d2a5!2sSan%20Francisco%2C%20CA%2C%20USA!5e0!3m2!1sen!2sph!4v1618935288348!5m2!1sen!2sph"
                    className="w-full h-64 md:h-full rounded-lg"
                    // allowFullScreen=""
                    loading="lazy"
                ></iframe>
            </div>
          </section>

          {/* About Event Section */}
          <section className="p-6 bg-white">
            <h3 className="text-2xl font-bold text-gray-800">About Event</h3>
            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="flex items-start space-x-3">
                <div className="text-blue-500">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <div>
                  <h4 className="text-lg font-semibold">Duration</h4>
                  <p>5 hours</p>
                </div>
              </div>
              <div className="flex items-start space-x-3">
                <div className="text-blue-500">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 7a4 4 0 014-4h10a4 4 0 014 4v10a4 4 0 01-4 4H7a4 4 0 01-4-4V7z" />
                  </svg>
                </div>
                <div>
                  <h4 className="text-lg font-semibold">Seats</h4>
                  <p>Email eTicket</p>
                </div>
              </div>
            </div>

            {/* Event Description */}
            <div className="mt-4">
              <p>
                <strong>Rock Revolt: A Fusion of Power and Passion</strong> is an electrifying rock music event that brings together a diverse lineup of talented rock bands and artists. The event aims to showcase the raw energy, intense power, and passion of rock music. Get ready to experience a night filled with unforgettable performances!
              </p>
            </div>
            {/* Bottom Close Button */}
            <div className="p-6 flex justify-end">
                <button onClick={handleClose} className="bg-red-600 text-white px-6 py-2 rounded-lg text-lg">
                Close
                </button>
            </div>
          </section>
        </div>
      </Dialog>
    </div>
  );
};

export default EventView;