import React, { Component } from 'react'

export default class CardMemos extends Component {
  render() {
    return (
      <div>
        <div className="bg-gray-50 p-8 rounded-md shadow-md">
        <div className="flex">
          <img src="../images/whatsNew/whatNewImagetableHeader.png" alt="What's New" className="mr-2 h-18 w-20" />
          <h2 className="text-2xl font-bold text-orange-500">Memos</h2>
        </div>
        <p className="text-gray-600 mb-8">
            Stay updated with the latest features, improvements, and announcements! Explore our ‘What’s New’ page to discover fresh content, product updates, and exciting developments happening in our community.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Memo Card */}
            <div className="bg-white rounded-md shadow-md p-4">
            <img src="../images/whatsNew/whatsNew3.png" alt="Memo" className="rounded-md mb-4" />
            <div className="text-orange-500 text-sm font-semibold mb-1">Category</div>
            <h3 className="text-xl font-bold text-gray-800 mb-2">Memo title</h3>
            <p className="text-gray-600 mb-4">Voluptate velit nulla cillum duis commodo excepteur esse laboris dolor esse. Tempor dolore aliqua eu elit ad laboris incididunt et.</p>
            <div className="flex items-center">
                <img src="../images/jimmy.png" alt="Author" className="rounded-full w-10 h-10 mr-3" />
                <div>
                <p className="text-sm font-semibold text-gray-800">Isabel Ortiz</p>
                <p className="text-sm text-gray-500">Sunday, July 23, 2023</p>
                </div>
            </div>
            </div>

            {/* Repeat Memo Card */}
            <div className="bg-white rounded-md shadow-md p-4">
            <img src="../../images/whatsNew/whatsNew2.png" alt="Memo" className="rounded-md mb-4" />
            <div className="text-orange-500 text-sm font-semibold mb-1">Category</div>
            <h3 className="text-xl font-bold text-gray-800 mb-2">Memo title</h3>
            <p className="text-gray-600 mb-4">Voluptate velit nulla cillum duis commodo excepteur esse laboris dolor esse. Tempor dolore aliqua eu elit ad laboris incididunt et.</p>
            <div className="flex items-center">
                <img src="../images/jimmy.png" alt="Author" className="rounded-full w-10 h-10 mr-3" />
                <div>
                <p className="text-sm font-semibold text-gray-800">Ryan Martinez</p>
                <p className="text-sm text-gray-500">Thursday, December 22, 2022</p>
                </div>
            </div>
            </div>

            {/* Repeat Memo Card */}
            <div className="bg-white rounded-md shadow-md p-4">
            <img src="../images/whatsNew/whatsNew1.png" alt="Memo" className="rounded-md mb-4" />
            <div className="text-orange-500 text-sm font-semibold mb-1">Category</div>
            <h3 className="text-xl font-bold text-gray-800 mb-2">Memo title</h3>
            <p className="text-gray-600 mb-4">Voluptate velit nulla cillum duis commodo excepteur esse laboris dolor esse. Tempor dolore aliqua eu elit ad laboris incididunt et.</p>
            <div className="flex items-center">
                <img src="../images/jimmy.png" alt="Author" className="rounded-full w-10 h-10 mr-3" />
                <div>
                <p className="text-sm font-semibold text-gray-800">Ryan Martinez</p>
                <p className="text-sm text-gray-500">Thursday, December 22, 2022</p>
                </div>
            </div>
            </div>
        </div>

        {/* Dropdown */}
        <div className="mt-8 flex justify-end">
            <select className="border border-gray-300 rounded-md px-3 py-2 text-gray-700">
            <option>September 2024</option>
            {/* Add more options as needed */}
            </select>
        </div>
        </div>
      </div>
    )
  }
}

