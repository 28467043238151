import React, { useState } from 'react';
import { Box, Paper, IconButton, Tooltip } from '@mui/material';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';

function RichText() {
  const [editorState, setEditorState] = useState(''); // Placeholder state

  const handleStyleToggle = (style: string) => {
    // Add logic to toggle style
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 850, mx: 'auto', my: 2 }}>
      <Paper variant="outlined" sx={{ borderColor: 'purple', padding: 1, position: 'relative' }}>
        <Box
          component="div"
          contentEditable
          sx={{
            minHeight: 150,
            padding: 2,
            outline: 'none',
            border: '1px solid #ccc',
          }}
        >
          Type here...
        </Box>

        {/* Toolbar at bottom right */}
        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            bottom: 8,
            right: 8,
            gap: 1,
          }}
        >
          <Tooltip title="Bold">
            <IconButton onClick={() => handleStyleToggle('BOLD')} size="small">
              <FormatBoldIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Italic">
            <IconButton onClick={() => handleStyleToggle('ITALIC')} size="small">
              <FormatItalicIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Strikethrough">
            <IconButton onClick={() => handleStyleToggle('STRIKETHROUGH')} size="small">
              <FormatStrikethroughIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Underline">
            <IconButton onClick={() => handleStyleToggle('UNDERLINE')} size="small">
              <FormatUnderlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Text Color">
            <IconButton onClick={() => handleStyleToggle('TEXT_COLOR')} size="small">
              <FormatColorTextIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Align Left">
            <IconButton onClick={() => handleStyleToggle('ALIGN_LEFT')} size="small">
              <FormatAlignLeftIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Align Center">
            <IconButton onClick={() => handleStyleToggle('ALIGN_CENTER')} size="small">
              <FormatAlignCenterIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Align Right">
            <IconButton onClick={() => handleStyleToggle('ALIGN_RIGHT')} size="small">
              <FormatAlignRightIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Bullet List">
            <IconButton onClick={() => handleStyleToggle('BULLET_LIST')} size="small">
              <FormatListBulletedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Numbered List">
            <IconButton onClick={() => handleStyleToggle('NUMBERED_LIST')} size="small">
              <FormatListNumberedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Insert Image">
            <IconButton onClick={() => handleStyleToggle('INSERT_IMAGE')} size="small">
              <InsertPhotoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </Paper>
    </Box>
  );
}

export default RichText;
