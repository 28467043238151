import React, { useEffect, useMemo, useRef, useState } from "react";
import { FaFolder } from "react-icons/fa6";
import { HiOutlineDotsVertical } from "react-icons/hi";
import TextThumbnail from "./OtherDocsComponent/TextThumbnail";
import ImageThumbnail from "./OtherDocsComponent/ImageThumbnail";
import PDFThumbnail from "./OtherDocsComponent/PDFThumbnail";
import { Worker } from "@react-pdf-viewer/core";

export default function OtherDocs() {
  const [currOpen, setCurrOpen] = useState<number | null>(null);
  const fileUrl = useMemo(
    () =>
      `${process.env.PUBLIC_URL}/Aldrene Aguilar_Software Developer_Resume.pdf`,
    []
  );
  const docFilesHolder = [
    "Policies",
    "Procedures",
    "Forms",
    "Forms",
    "Forms",
    "Others",
    "Brand Guideline",
  ];

  return (
    <div className="bg-white rounded-md px-8 py-4 mt-4">
      <div className="">
        <h2 className="text-2xl font-semibold">Folders</h2>
      </div>
      <div className="flex gap-6 my-8 flex-wrap">
        {docFilesHolder.map((item, index) => (
          <FolderOption
            key={`item-${index}`}
            index={index}
            item={item}
            currOpen={currOpen}
            setCurrOpen={setCurrOpen}
          />
        ))}
      </div>
      <hr className="h-[2px] bg-gray-300 mt-12 mb-12 border-0" />
      <div className="mt-8">
        <h2 className="text-2xl font-semibold">Files</h2>
      </div>
      <div className="flex gap-6 flex-wrap mt-4 mb-12">
        {Array.from({ length: 10 }).map((_, index) => {
          return (
            <div className="w-64 min-h-72 rounded-t-md bg-gray-100 border border-gray-200 flex flex-col">
              {/* <div className="flex-grow bg-white mx-6 mt-6">test {index}</div> */}
              <div className="flex-grow bg-white mx-6 mt-6 rounded-md">
                {index % 3 === 0 ? (
                  <PDFThumbnail fileUrl={fileUrl} pageIndex={1} />
                ) : index % 2 === 0 ? (
                  <ImageThumbnail />
                ) : (
                  <TextThumbnail />
                )}
                {/* <ImagePreview /> */}
              </div>

              <div className="bg-white h-16 py-4 px-4">
                <span>(Demo) TVC Concept</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

type FolderOptionProp = {
  item: string;
  index: number;
  currOpen: number | null;
  setCurrOpen: React.Dispatch<React.SetStateAction<number | null>>;
};
const FolderOption = ({
  item,
  index,
  currOpen,
  setCurrOpen,
}: FolderOptionProp) => {
  const isOpen = index === currOpen;
  const handleToggle = () => {
    setCurrOpen(isOpen ? null : index);
  };
  const innerRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (innerRef.current && !innerRef.current.contains(event.target as Node)) {
      setCurrOpen(null); // Reset toggle when clicking outside
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="w-64 flex justify-between px-4 items-center py-3 border rounded-md border-opacity-50">
      <div className="flex gap-3 items-center">
        <span className="text-xl text-[#EFB034FF]">
          <FaFolder />
        </span>
        <span>{item}</span>
      </div>
      <span
        className="text-xl relative hover:cursor-pointer"
        onClick={handleToggle}
        ref={innerRef}
      >
        <HiOutlineDotsVertical />

        {isOpen && (
          <ul className=" bg-gray-100 rounded-md absolute flex flex-col gap-2 text-gray-400 z-10 right-2 text-sm">
            <li className="hover:bg-gray-500 px-8 py-2 hover:text-white">
              Download
            </li>
            <li className="hover:bg-gray-500 px-8 py-2 hover:text-white">
              Rename
            </li>
            <li className="hover:bg-gray-500 px-8 py-2 hover:text-white">
              Delete
            </li>
          </ul>
        )}
      </span>
    </div>
  );
};
