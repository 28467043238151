import { Button } from "antd";
import React, { useState } from "react";
import { FiMaximize2 } from "react-icons/fi";
import CustomedCalendar from "./CustomedCalendar";
import { empListLeaveHistory } from "../pages/EmployeeManagement/SampleDatas";
import {
  MdAddCall,
  MdOutlinePregnantWoman,
  MdOutlineSick,
} from "react-icons/md";
import { LuPalmtree } from "react-icons/lu";
import "./MaximizeCalendarModal.css";

interface LeaveItem {
  id: number;
  date: string;
  leaveType: number;
  profile: string;
}

interface GroupedLeave {
  date: string;
  leaveType: number;
  items: LeaveItem[];
}

export default function MaximizeCalendarModal() {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const leaveHistory: LeaveItem[] = empListLeaveHistory;
  const groupedData = leaveHistory.reduce<GroupedLeave[]>((acc, item) => {
    const existingGroup = acc.find(
      (group) => group.date === item.date && group.leaveType === item.leaveType
    );

    if (existingGroup) {
      // Add item to existing group
      existingGroup.items.push(item);
    } else {
      // Create a new group if no match found
      acc.push({ date: item.date, leaveType: item.leaveType, items: [item] });
    }

    return acc;
  }, []);
  const handleMaximizeCal = () => setIsOpen(!isOpen);

  const getIconStatus = (type: number) => {
    const emoType = [
      "",
      <MdOutlineSick />,
      <LuPalmtree />,
      <MdAddCall />,
      <MdOutlinePregnantWoman />,
      <LuPalmtree />,
    ];

    return emoType[type];
  };

  const getLeaveType = (type: number) => {
    const leaveStatus = [
      "",
      "Sick leave",
      "Vacation leave",
      "Emergency leave",
      "Maternity leave",
      "Vacation leave",
    ];
    return leaveStatus[type];
  };
  return (
    <>
      <Button type="text" onClick={handleMaximizeCal}>
        <FiMaximize2 />
      </Button>
      {isOpen && (
        <>
          {/* <div className="border-2 h-full z-50 fixed inset-0 flex justify-end bg-black bg-opacity-50 overflow-auto"> */}
          <div className="fixed inset-0 z-50 flex justify-end bg-black bg-opacity-50">
            <div className="bg-white w-1/3 max-w-md overflow-y-auto hide-scrollbar">
              <CustomedCalendar isOpen={isOpen} />
              <hr />
              <div className="m-4">
                <p className="text-xl font-semibold">Leave History</p>
                <div className="mt-4">
                  <ul className="flex flex-col gap-4">
                    {groupedData.map((item, index) => {
                      return (
                        <div className="flex gap-4">
                          <div className="">
                            <span className="aspect-square object-fill w-[52px] rounded-full text-2xl flex justify-center items-center bg-gray-100 text-cyan-500">
                              {getIconStatus(item.leaveType)}
                            </span>
                          </div>
                          <p>
                            <span>{item.date}</span>
                          </p>
                          <div className="flex flex-col gap-2 justify-center">
                            <span>{getLeaveType(item.leaveType)}</span>
                            <li className="flex gap-2">
                              {item.items.map((g, index) => (
                                <li key={g.id}>
                                  <div>
                                    <div className=" flex justify-center items-center">
                                      <img
                                        src={g.profile}
                                        className="aspect-square object-fill w-6 rounded-full"
                                        alt={g.profile}
                                      />
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </li>
                          </div>
                        </div>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <Button
                type="text"
                onClick={handleMaximizeCal}
                className="absolute top-0 right-0 m-4"
              >
                X
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
}
