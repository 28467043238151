import React from 'react';
import { Button, Checkbox, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import MemoModal from '../../modals/WhatsNew/MemoModal';

const memos = [
  { title: 'Introducing our Innovative Employee Wellness Program, prioritizing team well-being', date: 'Today', author: 'Mike Taylor' },
  { title: 'Achieving the Gold Standard in Workplace Safety for a secure environment', date: 'Today', author: 'Mike Taylor' },
  { title: 'Celebrating a Milestone: 10 Years of Employee Success', date: '22 Nov', author: 'Mike Taylor' },
  { title: 'Engage with our Exciting Employee Events for team bonding', date: '18 Nov', author: 'Mike Taylor' },
  { title: 'Discover work-life harmony with our HR’s Flexible Work Policies', date: '17 Nov', author: 'Mike Taylor' },
  { title: 'Team-Building Hike: Strengthening Bonds, One Step at a Time', date: '11 Nov', author: 'Mike Taylor' },
  { title: 'Yoga Retreat Day: Nurturing Physical and Mental Resilience', date: '11 Nov', author: 'Mike Taylor' },
  { title: 'Tech Talks Series: Exploring Cutting-Edge Innovations in the Workplace', date: '11 Nov', author: 'Mike Taylor' },
  { title: 'Client Success Stories: Showcasing the Impact of Our Products and Services', date: '10 Nov', author: 'Mike Taylor' },
  { title: 'Spotlight on Innovation: Showcasing Employee-Driven Creative Projects', date: '09 Nov', author: 'Mike Taylor' },
];

const TableMemo = () => {
  return (
    <div className="bg-gray-50 p-8">
        <div className="flex">
            <img src="../images/whatsNew/whatNewImagetableHeader.png" alt="What's New" className="mr-2 h-18 w-20" />
            <Typography variant="h4" className="text-yellow-600 font-semibold mb-4">
                Memos
            </Typography>
        </div>
        <Typography variant="body1" className="text-gray-700 mb-8">
            Stay updated with the latest features, improvements, and announcements! Explore our 'What's New' page to discover fresh content, product updates, and exciting developments happening in our community.
        </Typography>
        <div className="border-t border-b border-yellow-600 p-4">
        {memos.map((memo, index) => (
          <div key={index} className="flex items-center justify-between py-4 border-b">
            <div className="flex items-center">
              <Checkbox />
              <div className="ml-4">
                {/* <Typography variant="body1" className="font-medium text-gray-800">{memo.title}</Typography> */}
                <MemoModal title={memo.title} />
                <Typography variant="body2" className="text-gray-500">{memo.date} | {memo.author}</Typography>
              </div>
            </div>
            <Button startIcon={<EditIcon />} className="text-indigo-500 hover:text-indigo-700">
              Edit
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TableMemo;