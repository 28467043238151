import {
  DownCircleFilled,
  LinkedinFilled,
  MailFilled,
} from "@ant-design/icons";
import { Modal, Popover, Space, Typography } from "antd";
import React, { useState } from "react";
import { HiChevronDoubleDown } from "react-icons/hi";
import { departmentEmployees } from "../pages/EmployeeManagement/SampleDatas";

interface ViewMoreTeamProps {
  department: string;
  id: number;
}

interface CardInfo {
  id: number;
  position: string;
  name: string;
  department: string;
}

const ViewMoreTeam = ({ department, id }: ViewMoreTeamProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const card: CardInfo[] = departmentEmployees(department);

  const { Text } = Typography;
  console.log(
    card.filter((item, index) => item.position.toLowerCase().includes("head"))
  );
  return (
    <>
      <HiChevronDoubleDown
        onClick={showModal}
        className=" text-[#0056D2] hover:text-[#0058d2c8] text-3xl cursor-pointer"
      />
      <Modal
        title={
          <Text className=" font-sans text-2xl">{department} Department</Text>
        }
        width={700}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        cancelText="Close"
      >
        <Space className="flex flex-wrap justify-center mt-4 gap-4">
          {card
            .filter(
              (item, index) =>
                item.position.toLowerCase().includes("head") || index === 0
            )
            .map(({ id, position, name }) => (
              <Space
                className="flex flex-col items-center justify-center h-40 w-40 my-4"
                key={id}
              >
                <img
                  src="../images/jimmy.png"
                  alt="prof"
                  className="aspect-square object-cover shadow-xl shadow-blue-300 w-28 rounded-full"
                />
                <div className="flex flex-col justify-center items-center">
                  <p className="font-sans text-2xl text-[#0056D2] font-bold leading-tight mt-8">
                    {name}
                  </p>
                  <p className=" font-sans text-sm leading-tight font-medium text-neutral-600 mt-1">
                    {position}
                  </p>
                </div>
              </Space>
            ))}
        </Space>
        <Space className="flex flex-wrap justify-center mt-9 gap-5">
          {card
            .filter(
              (item, index) =>
                !item.position.toLowerCase().includes("head") || index !== 0
            )
            .map(({ id, position, name }) => (
              <Space
                className="flex flex-col items-center justify-center h-36 w-36 my-6"
                key={id}
              >
                <img
                  src="../images/jimmy.png"
                  alt="prof"
                  className="aspect-square object-cover shadow-xl shadow-blue-300 w-28 rounded-full"
                />
                <div className="flex flex-col justify-center items-center">
                  <p className=" font-sans text-base text-[#0056D2] font-bold leading-tight mt-8">
                    {name}
                  </p>
                  <p className=" font-sans text-xs leading-tight font-medium text-neutral-600 mt-1 text-center">
                    {position}
                  </p>
                </div>
              </Space>
            ))}
        </Space>
      </Modal>
    </>
  );
};

export default ViewMoreTeam;
