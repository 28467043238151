import { Button, Modal, Space } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";
import { FaX } from "react-icons/fa6";
import { TfiClip } from "react-icons/tfi";

export default function DeclineModal() {
  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const showModal = () => setIsModalOpen(true);
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancelApprove = () => {
    setIsApproved(false);
    setIsModalOpen(true);
  };

  const showApproved = () => {
    setIsApproved(true);
    setIsModalOpen(false);
  };
  const buttonStyle = {
    color: "white",
    backgroundColor: isHovered ? "#538d38ff" : "#68b046ff", // Change color on hover
    transition: "background-color 0.3s ease", // Smooth transition effect
  };
  return (
    <div>
      <Button type="text" style={{ color: "#9095a0ff" }} onClick={showModal}>
        Decline
      </Button>
      <Modal open={isModalOpen} footer={null} onCancel={handleCancel}>
        <Space className="flex flex-wrap justify-center mt-4 gap-4">
          <h2 className="text-[#0056d2] font-bold text-lg">Decline Leave?</h2>
        </Space>
        <Space className="flex flex-col justify-center mt-6 text-[#565e6cff]">
          <p className="flex flex-col items-center text-base">
            <span className="font-semibold text-base mb-1">
              Are you sure you want to decline leave?
            </span>
            <span className="flex flex-col items-center justify-center">
              <span>
                Declining this leave request means the employee will not
              </span>
              <span>be granted the time off.</span>
            </span>
          </p>
          <p className="mt-4 flex flex-col items-center text-base">
            <span>As an alternative, you may choose to grant them unpaid</span>
            <span>leave instead of fully declining the request.</span>
          </p>
          <p>
            {/* <Link to={'#'}>Click her efor granting unpaid leave</Link> */}
            <span className="underline underline-offset-4 text-[#0056d2]">
              Click here for granting unpaid leave
            </span>
          </p>
        </Space>
        <Space className="mt-5 flex flex-col items-center gap-3">
          <TextArea
            // value={value}
            // onChange={handleChange}
            placeholder="Input remarks (50 character limit)"
            rows={3}
            style={{ resize: "none" }}
            maxLength={50}
            autoSize={false}
            className="w-96"
          />
          <div className="w-96 border-2">
            <p className="opacity-70 m-2 flex gap-1 items-center">
              <span className="text-lg">
                <TfiClip />
              </span>
              Input/attach signature
            </p>
          </div>
        </Space>
        <Space className="mt-8 mb-10 flex justify-center">
          <Button
            style={{ color: "white", backgroundColor: "#9095a0ff" }}
            onClick={showApproved}
          >
            Decline Leave
          </Button>
          <Button type="text" style={{ color: "#9095a0ff" }}>
            Go back
          </Button>
        </Space>
      </Modal>
      {isApproved && (
        <Modal open={isApproved} footer={null} onCancel={handleCancelApprove}>
          <Space className="flex flex-wrap justify-center mt-4 gap-4 mb-16">
            <h2 className="text-[#0056d2] font-bold text-lg">Declined Leave</h2>
          </Space>
          <Space className="flex justify-center gap-7">
            <p className="flex items-center">
              <span className="text-6xl text-red-500">
                <FaX />
              </span>
              <span className="text-base">
                You have <strong>declined</strong> this leave request.
              </span>
            </p>
          </Space>
          <Space className="mb-16 flex justify-center mt-12">
            <Button
              type="text"
              style={{ color: "#9095a0ff" }}
              onClick={handleCancelApprove}
            >
              Go back
            </Button>
          </Space>
        </Modal>
      )}
    </div>
  );
}
