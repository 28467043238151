import * as React from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { thumbnailPlugin } from "@react-pdf-viewer/thumbnail";

import "@react-pdf-viewer/core/lib/styles/index.css";

import { pageThumbnailPlugin } from "./pageThumbnailPlugin";

interface DisplayThumbnailExampleProps {
  fileUrl: string;
  pageIndex: number;
}

const PDFThumbnail = React.memo(
  ({ fileUrl, pageIndex }: DisplayThumbnailExampleProps) => {
    const thumbnailPluginInstance = thumbnailPlugin();
    const { Cover } = thumbnailPluginInstance;
    const pageThumbnailPluginInstance = pageThumbnailPlugin({
      PageThumbnail: <Cover width={200} getPageIndex={() => pageIndex} />,
    });

    return (
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <Viewer
          fileUrl={fileUrl}
          plugins={[pageThumbnailPluginInstance, thumbnailPluginInstance]}
        />
      </Worker>
    );
  }
);

export default PDFThumbnail;
