import { Button, Table, TableColumnsType } from "antd";
import React from "react";

type AttendanceTable = {
  id: number;
  lastName: string;
  firstName: string;
  leaveType: number;
  paid: boolean;
  status: 1 | 2 | 3;
};

type AttendanceTableProp = {
  data: AttendanceTable[];
  openProfile: (open: boolean) => void;
  empId: React.Dispatch<React.SetStateAction<number>>;
};
export default function EmpAttendanceTable({
  data,
  openProfile,
  empId,
}: AttendanceTableProp) {
  const getLeaveType = (type: number) => {
    const types = ["", "Sick Leave", "Vocation Leave", "Maternity Leave"];
    return types[type];
  };

  const getStatus = (type: number) => {
    const stat = ["", "Ongoing", "Approved", "Completed"];
    return stat[type];
  };

  const getStatColor = (type: number) => {
    const color = [
      "",
      "text-[#98690CFF] bg-[#fef9eeff]",
      "text-[#379AE6FF] bg-[#c1e4ff]",
      "text-[#117b34ff] bg-[#ccffdd]",
    ];
    return color[type];
  };

  const handleOpenEmpDtl = (id: number) => {
    empId(id);
    openProfile(true);
  };

  const columns: TableColumnsType<AttendanceTable> = [
    {
      key: "id",
      dataIndex: "id",
      width: 100,
      align: "center",
      title: () => <p className="font-sans text-zinc-700">Employee ID</p>,
    },
    {
      key: "lastName",
      dataIndex: "lastName",
      width: 100,
      align: "center",
      title: () => <p className="font-sans text-zinc-700">Last Name</p>,
    },
    {
      key: "firstName",
      dataIndex: "firstName",
      width: 100,
      align: "center",
      title: () => <p className="font-sans text-zinc-700">Fist Name</p>,
    },
    {
      key: "leaveType",
      dataIndex: "leaveType",
      width: 100,
      align: "center",
      title: () => <p className="font-sans text-zinc-700">Leave Type</p>,
      render: (type) => {
        return <p>{getLeaveType(type)}</p>;
      },
    },
    {
      key: "paid",
      dataIndex: "paid",
      width: 100,
      align: "center",
      title: () => <p className="font-sans text-zinc-700">Paid</p>,
      render: (paid) => {
        return <input type="checkbox" checked={paid} />;
      },
    },
    {
      key: "status",
      dataIndex: "status",
      width: 100,
      align: "center",
      title: () => <p className="font-sans text-zinc-700">Status</p>,
      render: (status, record) => {
        return (
          <Button
            className={`${getStatColor(status)} rounded-full p-2 w-24`}
            onClick={() => handleOpenEmpDtl(record.id)}
          >
            {getStatus(status)}
          </Button>
        );
      },
    },
  ];
  return (
    <div>
      <Table
        style={{ borderBlockColor: "#d4d4d8" }}
        columns={columns}
        dataSource={data}
      />
    </div>
  );
}
