import React from "react";
import EmployeeIcon from "../../../reuseables/EmployeeIcon";

type ThisWeekProps = {
  profile: string;
  firstName: string;
  lastName: string;
  leaveType: number;
};
export default function ThisWeekCard({
  profile,
  firstName,
  lastName,
  leaveType,
}: ThisWeekProps) {
  const leaveTypeString = (type: number) => {
    const types = ["", "Sick Leave", "Vocation Leave", "Maternity Leave"];
    return types[type];
  };
  return (
    <div className="flex gap-4 items-center">
      <EmployeeIcon profile={profile} />
      <div>
        <p className="font-bold text-lg">
          <span>
            {firstName} {lastName}
          </span>
        </p>
        <p>
          <span>{leaveTypeString(leaveType)}</span>
        </p>
      </div>
    </div>
  );
}
