import {
  FileTextOutlined,
  FolderFilled,
  FolderOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  ConfigProvider,
  Descriptions,
  DescriptionsProps,
  Space,
  Table,
  TableColumnsType,
  Typography,
} from "antd";
import React from "react";

type DocumentTable = {
  // status: ["Signed", "Not Signed", "To Update", "Pending", "Not Applicable"];
  status: string;
  signature: string;
  checklist: string;
  document: string;
  deadline: string;
  daysToComplete: string;
  dateCompleted: string;
  completedWTimeFrame: boolean;
};

const sampleData: DocumentTable[] = [
  {
    status: "Signed",
    signature: "E-signature",
    checklist: "Employment Contract",
    document: "file",
    deadline: "09/26/24",
    daysToComplete: "Complete",
    dateCompleted: "",
    completedWTimeFrame: true,
  },
];
const ViewProfileDocuments = () => {
  const { Text, Paragraph } = Typography;

  interface EmployeeInformationApi {
    key: string;
    label: string;
  }

  const document: EmployeeInformationApi[] = [
    {
      key: "1",
      label: "Government Files",
    },
    {
      key: "2",
      label: "Contract",
    },
    {
      key: "3",
      label: "Attachment1",
    },
  ];

  // function for rendering the description labels and value
  const returnEmployeeInformation = (
    arr: EmployeeInformationApi[]
  ): DescriptionsProps["items"] => {
    return arr.map((entry) => ({
      key: entry.key,
      children: (
        <div className=" flex items-center space-x-3  justify-center ">
          <FolderFilled className=" text-[#f6c445] text-lg" />
          <p className=" font-sans text-neutral-500">{entry.label}</p>
        </div>
      ),
    }));
  };

  const columns: TableColumnsType<DocumentTable> = [
    {
      key: "status",
      dataIndex: "status",
      width: 100,
      align: "center",
      title: () => <p className="font-sans text-zinc-700">Status</p>,
      render: (profile) => {
        return (
          <div className=" flex justify-center items-center">{profile}</div>
        );
      },
    },
    {
      key: "signature",
      dataIndex: "signature",
      width: 120,
      align: "center",
      title: () => <p className="font-sans text-zinc-700">Signature</p>,
    },
    {
      key: "checklist",
      dataIndex: "checklist",
      width: 120,
      align: "center",
      title: () => <p className="font-sans text-zinc-700">Checklist</p>,
    },
    {
      key: "document",
      dataIndex: "document",
      width: 120,
      align: "center",
      title: () => <p className="font-sans text-zinc-700">Documents</p>,
    },
    {
      key: "deadline",
      dataIndex: "deadline",
      width: 120,
      align: "center",
      title: () => <p className="font-sans text-zinc-700">Deadline</p>,
    },
    {
      key: "daysToComplete",
      dataIndex: "daysToComplete",
      width: 120,
      align: "center",
      title: () => <p className="font-sans text-zinc-700">Days To Complete</p>,
    },
    {
      key: "dateCompleted",
      dataIndex: "dateCompleted",
      width: 120,
      align: "center",
      title: () => <p className="font-sans text-zinc-700">Date Completed</p>,
    },
    {
      key: "completedWTimeFrame",
      dataIndex: "completedWTimeFrame",
      width: 120,
      align: "center",
      title: () => (
        <p className="font-sans text-zinc-700">Completed within timeframe</p>
      ),
      render: (status) => (status ? "✓" : ""),
    },
    // {
    //   key: "name",
    //   dataIndex: "name",
    //   title: () => <p className="font-sans text-zinc-700">Name</p>,
    //   render: (text, record) => (
    //     <Link
    //       to="/index/my-detailed-profile"
    //       state={{ text }}
    //       className="underline underline-offset-4 hover:text-blue-500"
    //     >
    //       {text}
    //     </Link>
    //   ),
    // },
    // {
    //   key: "status",
    //   dataIndex: "status",
    //   width: 100,
    //   title: () => <p className="font-sans text-zinc-700">Status</p>,
    //   render: (status: number) => {
    //     const statusColor = statusList.find((entry) => entry.id === status);
    //     return <Tag className={statusColor?.color}>{statusColor?.label}</Tag>;
    //   },
    // },
  ];

  const documents = returnEmployeeInformation(document);

  return (
    <>
      <Card
        title={
          <div className=" flex justify-between items-center">
            <Space direction="horizontal" className="text-xl space-x-3 text">
              <FileTextOutlined className="text-[#0056D2] text-2xl" />
              <Text className="font-sans text-xl text-neutral-600">
                Documents
              </Text>
            </Space>
            <Space>
              <ConfigProvider theme={{ token: { colorPrimary: "#ea580c" } }}>
                <Button
                  icon={<UploadOutlined />}
                  className=" font-sans"
                  type="primary"
                >
                  Upload
                </Button>
              </ConfigProvider>
            </Space>
          </div>
        }
        className=" mt-10"
      >
        <Descriptions
          items={documents}
          column={3}
          className=" border-b border-neutral-300 pb-5"
        />
      </Card>
      <div className="mt-4">
        <Table
          style={{ borderBlockColor: "#d4d4d8" }}
          bordered
          rowKey="id"
          pagination={false}
          columns={columns}
          dataSource={sampleData}
          rowClassName=" font-sans text-neutral-600"
        />
      </div>
    </>
  );
};

export default ViewProfileDocuments;
