import React, { useState } from 'react';
import {
   Dialog, DialogContent, DialogActions,
  TextField, Button, IconButton
} from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import { Typography } from "antd";
import { FileAddOutlined } from "@ant-design/icons";
import RichText from '../../inputs/RichText';

const AddMemo = () => {
  const [open, setOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  // Function to open the modal
  const handleOpen = () => setOpen(true);

  // Function to close the modal
  const handleClose = () => {
    setOpen(false);
    setImagePreview(null);
  };

  // Function to handle image selection
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const imageURL = URL.createObjectURL(file);
      setImagePreview(imageURL);
    }
  };

  return (
    <div>
      {/* Button to Open Modal */}
      <Button variant="contained" onClick={handleOpen} color="warning">
        <FileAddOutlined className="mr-2" />
        <Typography.Text className="font-sans text-slate-100">
          Add New
        </Typography.Text>
      </Button>

      {/* Full-Screen Modal for Adding Memo */}
      <Dialog open={open} onClose={handleClose} fullScreen>
        <DialogContent dividers className="flex items-center justify-center">
          <div className="w-[850px]">
            <div className="flex items-center space-x-5 mt-8">
              <img src="/images/paperPlus.png" alt="Paper Plus" />
              <Typography>Add Memo</Typography>
            </div>
            {/* Cover Photo Section */}
            <div className="bg-gray-100 h-60 flex items-center justify-center rounded-lg mb-6 relative">
              {imagePreview ? (
                <img src={imagePreview} alt="Cover Preview" className="h-60 w-auto object-contain rounded-lg" />
              ) : (
                <span className="text-gray-400 text-lg">Add Cover Photo</span>
              )}
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
                className="absolute bottom-2 right-2"
              >
                <input hidden accept="image/*" type="file" onChange={handleImageChange} />
                <PhotoCamera />
              </IconButton>
            </div>

            {/* Title and Date Fields */}
            <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-4">
              <TextField
                label="Title"
                variant="outlined"
                className="mb-4"
                InputLabelProps={{
                  style: { fontSize: 18, fontWeight: 'bold', color: '#333' },
                }}
              />

              {/* Date Picker */}
              <TextField
                type="date"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                inputProps={{ style: { padding: '16.5px 14px' } }}
              />
            </div>

            {/* Category Dropdown */}
            <div className="mb-4">
              <TextField
                select
                SelectProps={{ native: true }}
                variant="outlined"
                InputLabelProps={{
                  style: { fontSize: 18, fontWeight: 'bold', color: '#333' },
                }}
              >
                <option value="">Select Category</option>
                <option value="general">General</option>
                <option value="announcement">Announcement</option>
                <option value="update">Update</option>
              </TextField>
            </div>

            {/* Memo Content */}
            <div className="mb-4">
              <RichText/>
            </div>

            {/* Posted By */}
            <div className="mb-4">
              <TextField
                label="Posted by"
                variant="outlined"
                InputLabelProps={{
                  style: { color: '#333' },
                }}
              />
            </div>
            <div className="flex items-center justify-center py-4">
              <Button onClick={handleClose} variant="contained" color="success">
                Post
              </Button>
              <Button onClick={handleClose} >Cancel</Button>
            </div>
          </div>
        </DialogContent>
        {/* Action Buttons */}
        <DialogActions >
          <Button onClick={handleClose} color="secondary">Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddMemo;
