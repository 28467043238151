import React, { useState } from "react";

type ImageType = {
    url: string;
    name: string;
    loading: boolean;
};

function MultipleUploadImages() {
    const [images, setImages] = useState<ImageType[]>([]);

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const newImage: ImageType = { 
        url: URL.createObjectURL(file), 
        name: file.name, 
        loading: false 
      };
      setImages((prevImages) => [...prevImages, newImage]);
    }
  };

  const handleAddImageClick = () => {
    const fileInput = document.getElementById("fileInput") as HTMLInputElement | null;
    if (fileInput) {
      fileInput.click();
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-gray-600 font-medium mb-4">Upload images for your event</h2>
      <div className="flex space-x-4">
        {images.map((image, index) => (
          <div key={index} className="flex flex-col items-center">
            <div className="w-24 h-24 bg-gray-200 rounded-md overflow-hidden relative">
              {image.loading ? (
                <div className="flex items-center justify-center w-full h-full">
                  <div className="animate-spin h-8 w-8 text-blue-500">
                    <svg
                      className="h-full w-full"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                      ></path>
                    </svg>
                  </div>
                </div>
              ) : (
                <img src={image.url} alt={image.name} className="w-full h-full object-cover" />
              )}
            </div>
            <span className="text-gray-500 text-sm mt-1">{image.name}</span>
          </div>
        ))}

        {/* Placeholder for image loading */}
        <div className="flex flex-col items-center">
          <div className="w-24 h-24 bg-gray-200 rounded-md border-dashed border-2 border-gray-300 flex items-center justify-center">
            <div className="animate-spin h-8 w-8 text-blue-500">
              <svg
                className="h-full w-full"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                ></path>
              </svg>
            </div>
          </div>
          <span className="text-gray-500 text-sm mt-1">Image-03.png</span>
        </div>

        {/* Placeholder for "Add new image" */}
        <div className="flex flex-col items-center">
          <div
            onClick={handleAddImageClick}
            className="w-24 h-24 bg-gray-200 rounded-md border-dashed border-2 border-gray-300 flex items-center justify-center cursor-pointer"
          >
            <span className="text-gray-400 text-2xl">+</span>
          </div>
          <span className="text-gray-500 text-sm mt-1">Add Image</span>
        </div>
      </div>

      <input
        id="fileInput"
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        className="hidden"
      />
    </div>
  );
}

export default MultipleUploadImages;