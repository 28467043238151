import React from "react";
import { Card, Image } from "antd";
import "./CardStyle.css";
const ImageThumbnail = () => {
  return (
    <Card className="no-padding-card">
      <Image
        alt="document-image"
        src={`${process.env.PUBLIC_URL}/images/avatar.jpg`}
        style={{ width: "100%", objectFit: "cover", borderRadius: "5px" }}
      />
    </Card>
  );
};

export default ImageThumbnail;
