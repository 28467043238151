import React, { useState } from "react";
import dayjs from "dayjs";
import { Button, Calendar, ConfigProvider } from "antd";
import { FiMaximize2 } from "react-icons/fi";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import enGB from "antd/es/locale/en_GB";
import "./Calendar.css";
import MaximizeCalendarModal from "./MaximizeCalendarModal";

type CustomedCalendarProp = {
  isOpen?: boolean;
};
export default function CustomedCalendar({ isOpen }: CustomedCalendarProp) {
  const [date, setDate] = useState(dayjs());

  // Function to go to the previous month
  const handlePrevMonth = () => {
    setDate(date.subtract(1, "month"));
  };

  // Function to go to the next month
  const handleNextMonth = () => {
    setDate(date.add(1, "month"));
  };

  const handleMaximizeCal = () => {};

  return (
    <ConfigProvider
      locale={{ locale: "en-gb" }}
      theme={{
        components: {
          Calendar: {
            miniContentHeight: 300,
          },
        },
        token: {
          colorPrimary: "#1890ff",
          colorTextHeading: "rgba(0, 155, 255, 0.88)",
        },
      }}
    >
      <Calendar
        fullscreen={false}
        className="my-calendar"
        value={date}
        headerRender={({ value, onChange }) => {
          const currentMonth = value.format("MMMM YYYY");
          return (
            <div
              style={{
                display: "flex",
                justifyContent: `${!isOpen ? "space-between" : ""}`,
                alignItems: "center",
                padding: "0 16px",
              }}
            >
              <span
                className={`${
                  !isOpen ? "font-bold text-2xl" : "font-bold text-xl"
                }`}
              >
                {currentMonth}
              </span>
              <div className="flex gap-3 m-4">
                <Button onClick={handlePrevMonth} className="font-bold">
                  <MdOutlineArrowBackIos />
                </Button>
                <Button onClick={handleNextMonth} className="font-bold">
                  <MdOutlineArrowForwardIos />
                </Button>
                {!isOpen && <MaximizeCalendarModal />}
              </div>
            </div>
          );
        }}
        onPanelChange={(newDate) => setDate(newDate)}
      />
    </ConfigProvider>
  );
}
