import React, { useState } from "react";
import { Dialog, Box, Typography, Button, IconButton, Divider } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
// import whatsNewImage from '../public/images/whatsNew/whatsNew2.png';

type MemoModalProps = {
    title: string;
};

const MemoModal: React.FC<MemoModalProps> = ({ title }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let other_image_list = [
    {
        "id": 1,
        "url": "/images/whatsNew/whatsNew1.png",
        "value": 1
    },
    {
        "id": 2,
        "url": "/images/whatsNew/whatsNew2.png",
        "value": 2
    },
    {
        "id": 3,
        "url": "/images/whatsNew/whatsNew3.png",
        "value": 3
    },
    {
        "id": 4,
        "url": "/images/whatsNew/event1.jpg",
        "value": 4
    }
  ]
  return (
    <div>
      {/* Button to open the modal */}
      <Typography variant="body1" className="font-medium text-gray-800 cursor-pointer" onClick={handleOpen}>
        {title}
      </Typography>

      <Dialog open={open} onClose={handleClose} fullScreen>
        <Box sx={{ position: "relative", bgcolor: "background.paper", overflowY: "auto" }}>
          {/* Close Button */}
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 16,
              right: 16,
              backgroundColor: "error.main",
              color: "white",
              borderRadius: "50%",
              width: 36,
              height: 36,
              "&:hover": { backgroundColor: "error.dark" },
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Blog Post Content */}
          <Box sx={{ p: 4, maxWidth: "800px", mx: "auto" }}>
            {/* Hero Image */}
            <Box
              component="img"
              src="/images/whatsNew/whatsNew2.png"
              alt="Blog Hero"
              sx={{
                width: "100%",
                borderRadius: 2,
                mb: 3,
              }}
            />

            {/* Title and Date */}
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <Typography variant="h4" fontWeight="bold">
                Title
              </Typography>
              <Typography variant="body2" color="textSecondary">
                September 7, 2024
              </Typography>
            </Box>

            {/* Category */}
            <Typography variant="body2" color="orange" fontWeight="bold" mb={2}>
              Category
            </Typography>

            {/* Content */}
            <Typography variant="body1" color="textSecondary" paragraph>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph>
              Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Typography>

            {/* Inline Images */}
            <Box display="flex" gap={2} mb={4}>
              <Box
                component="img"
                src="/images/whatsNew/whatsNew1.png"
                alt="Content Image"
                sx={{ width: "48%", borderRadius: 2 }}
              />
              <Box
                component="img"
                src="/images/whatsNew/whatsNew3.png"
                alt="Content Image"
                sx={{ width: "48%", borderRadius: 2 }}
              />
            </Box>

            {/* Author Section */}
            <Box display="flex" alignItems="center" mb={4}>
              <Box
                component="img"
                src="/images/jimmy.png"
                alt="Author"
                sx={{ width: 50, height: 50, borderRadius: "50%", mr: 2 }}
              />
              <Typography variant="subtitle1" color="textPrimary">
                Jimuel P. Tomas
              </Typography>
            </Box>

            {/* Divider */}
            <Divider sx={{ my: 3 }} />

            {/* Read Others Section */}
            <Typography variant="h5" fontWeight="bold" color="primary" textAlign="center" mb={2}>
              Read Others
            </Typography>
            <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap={3}>
              {other_image_list.map((item) => (
                <Box
                  key={item.value}
                  sx={{
                    p: 2,
                    border: 1,
                    borderRadius: 2,
                    borderColor: item.value === 4 ? "purple" : "grey.300",
                  }}
                >
                  <Box
                    component="img"
                    src={item.url}
                    alt="Blog Thumbnail"
                    sx={{ width: "100%", height: 100, objectFit: "cover", borderRadius: 1, mb: 1 }}
                  />
                  <Typography variant="subtitle1" fontWeight="bold">
                    Blog title
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};

export default MemoModal;
