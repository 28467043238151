import { Button, Checkbox, Modal, Space } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";
import { FaCheck } from "react-icons/fa6";
import { TfiClip } from "react-icons/tfi";

export default function ApprovedModal() {
  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const showModal = () => setIsModalOpen(true);
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancelApprove = () => {
    setIsApproved(false);
    setIsModalOpen(true);
  };

  const showApproved = () => {
    setIsApproved(true);
    setIsModalOpen(false);
  };
  const buttonStyle = {
    color: "white",
    backgroundColor: isHovered ? "#538d38ff" : "#68b046ff", // Change color on hover
    transition: "background-color 0.3s ease", // Smooth transition effect
  };
  const name = "Amantha Lopez";
  const remainingLeave = 12;
  return (
    <div>
      <Button
        style={{ color: "white", backgroundColor: "#68b046ff" }}
        onClick={showModal}
      >
        Approve
      </Button>
      <Modal open={isModalOpen} footer={null} onCancel={handleCancel}>
        <Space className="flex flex-wrap justify-center mt-4 gap-4">
          <h2 className="text-[#0056d2] font-bold text-lg">Approve Leave?</h2>
        </Space>
        <Space className="flex flex-col justify-center mt-6 text-[#565e6cff]">
          <p className="flex flex-col items-center text-base">
            <span>
              <span className="font-semibold">{name}</span> has {remainingLeave}{" "}
              (days) remaining leave.
            </span>
            <span>Do you wish to approve leave?</span>
          </p>
          <p className="text-sm mt-2 flex flex-col items-center">
            <span>Please indicate the type of leave by ticking the</span>
            <span> appropriate checkbox below:</span>
          </p>
          <div className="flex gap-4 items-center mt-4">
            <label htmlFor="" className="flex items-center">
              <Checkbox type="checkbox" checked={true} />
              <span className="ml-1 text-base">Paid</span>
            </label>
            <label htmlFor="" className="flex items-center">
              <Checkbox type="checkbox" checked={false} />
              <span className="ml-1 text-base">Unpaid</span>
            </label>
          </div>
        </Space>
        <Space className="mt-5 flex flex-col items-center gap-3">
          <TextArea
            // value={value}
            // onChange={handleChange}
            placeholder="Input remarks (50 character limit)"
            rows={3}
            style={{ resize: "none" }}
            maxLength={50}
            autoSize={false}
            className="w-96"
          />
          <div className="w-96 border-2">
            <p className="opacity-70 m-2 flex gap-1 items-center">
              <span className="text-lg">
                <TfiClip />
              </span>
              Input/attach signature
            </p>
          </div>
        </Space>
        <Space className="mt-8 mb-10 flex justify-center">
          <Button
            style={{ color: "white", backgroundColor: "#68b046ff" }}
            onClick={showApproved}
          >
            Approve Leave
          </Button>
          <Button type="text" style={{ color: "#9095a0ff" }}>
            Go back
          </Button>
        </Space>
      </Modal>
      {isApproved && (
        <Modal open={isApproved} footer={null} onCancel={handleCancelApprove}>
          <Space className="flex flex-wrap justify-center mt-4 gap-4 mb-16">
            <h2 className="text-[#0056d2] font-bold text-lg">Approved Leave</h2>
          </Space>
          <Space className="flex justify-center gap-7">
            <p className="flex items-center">
              <span className="text-6xl text-[#68b046ff]">
                <FaCheck />
              </span>
              <span className="text-base">
                You have successfully <strong>approved</strong> this leave
                request
              </span>
            </p>
          </Space>
          <Space className="mb-16 flex justify-center mt-12">
            <Button
              style={buttonStyle}
              onClick={showApproved}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              Go to Approved Leave
            </Button>
            <Button
              type="text"
              style={{ color: "#9095a0ff" }}
              onClick={handleCancelApprove}
            >
              Go back
            </Button>
          </Space>
        </Modal>
      )}
    </div>
  );
}
