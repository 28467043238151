import React, { useState } from "react";

import Header from "../../reuseables/Header";

import CustomContainer from "../../reuseables/CustomContainer";

import OtherDocs from "./DocumentComponents/OtherDoc";
import TwoOOne from "./DocumentComponents/TwoOOne";

export interface TabType {
  label: string;
  value: number;
  component?: JSX.Element;
}

const Documents = () => {
  const [activeTab, setActiveTab] = useState<TabType>({
    label: "201",
    value: 1,
    component: <TwoOOne />,
  });

  const tabs: TabType[] = [
    {
      label: "201",
      value: 1,
      component: <TwoOOne />,
    },
    {
      label: "Other documents",
      value: 2,
      component: <OtherDocs />,
    },
  ];
  // handle change for tabs
  const handleChange = (selected: number) => {
    const selectedTabs = tabs.find(
      ({ value }) => value === selected
    ) as TabType;

    setActiveTab(selectedTabs);
  };
  return (
    <>
      <Header>
        <div className=" flex items-center space-x-5">
          <Header.Title title="Documents" />
          <Header.Image src="../images/DocumentsImg.svg" />
          {Header.TabOption && (
            <Header.TabOption
              tabOptions={tabs}
              defaultTab={1}
              onChanges={handleChange}
            />
          )}
        </div>
        <div className="flex items-center space-x-5"></div>
      </Header>
      <CustomContainer>
        <div>{activeTab?.component}</div>
      </CustomContainer>
    </>
  );
};

export default Documents;
